import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import InvoiceReceiptsTransactionTable from "./invoiceReceiptsTransactionTable";
import { Button } from "rsuite";
import { Iconify } from "../../../../components";
import AddNewTransactionModal from "../../../../components/addNewTransactionModal/addNewTransactionModal";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { INCOMING_TRANSFER } from "../../../../ApiUrl";
import { toast } from "react-toastify";
import { fCurrency } from "../../../../utils/formatNumber";
import GroupedSelect from "../../../../components/GroupedSelect";
import { useSelector } from "react-redux";

const InvoiceReceiptsTransactions = ({ lenderList, details }) => {
  const user = useSelector((state) => state.profileReducer?.user);
  const { roles } = user;
  const [addTrans, setAddTrans] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("contract_payment");
  const [counts, setCounts] = useState({
    contract: 0,
    supplier: 0,
  });
  const [translist, setTranslist] = useState({
    count: 0,
    results: [],
  });
  const [transactionData, setTransactionData] = useState(null);

  async function getTransferTransactionList() {
    setLoading(true);
    merchantApi
      .get(`${INCOMING_TRANSFER}${details?.id}/transactions/`)
      .then((response) => {
        if (response?.data) {
          setLoading(false);
          setTransactionData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.data?.data?.message || "Error getting transaction list"
        );
      });
  }

  useEffect(() => {
    if (details) {
      getTransferTransactionList();
    }
  }, [details]);

  useEffect(() => {
    if (transactionData) {
      setCounts({
        contract: transactionData?.contract_payment?.length,
        supplier: transactionData?.supplier_payment?.length,
      });
      if (currentTab === "contract_payment") {
        setTranslist({
          count: transactionData?.contract_payment?.length,
          results: transactionData?.contract_payment,
        });
      } else {
        setTranslist({
          count: transactionData?.supplier_payment?.length,
          results: transactionData?.supplier_payment,
        });
      }
    }
  }, [transactionData]);

  const onTabFilterChange = (tab) => {
    if (tab === "contract_payment") {
      setTranslist({
        count: transactionData?.contract_payment?.length,
        results: transactionData?.contract_payment,
      });
    } else {
      setTranslist({
        count: transactionData?.supplier_payment?.length,
        results: transactionData?.supplier_payment,
      });
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      {(roles?.includes("owner") || roles?.includes("maker")) && (
        <Stack direction={"row"} justifyContent={"flex-end"} sx={{ mb: 3 }}>
          <Button
            className={`btn action-button primary_color text-white`}
            appearance="primary"
            type={"button"}
            style={{
              maxWidth: "300px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            // disabled={!formik.isValid}
            // loading={loading}
            onClick={() => setAddTrans(true)}
          >
            <Iconify icon="material-symbols:add" width={22} />
            Add New Transaction
          </Button>
        </Stack>
      )}
      <Box sx={{ mt: 3 }}>
        <InvoiceReceiptsTransactionTable
          translist={translist}
          onTabFilterChange={onTabFilterChange}
          counts={counts}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          fetchTranslistLoading={loading}
          getTransferTransactionList={getTransferTransactionList}
          roles={roles}
        />
      </Box>
      {addTrans && (
        <AddNewTransactionModal
          isOpen={addTrans}
          onClose={() => setAddTrans(false)}
          lenderList={lenderList}
          details={details}
          onSuccess={getTransferTransactionList}
        />
      )}
    </Box>
  );
};

export default InvoiceReceiptsTransactions;
