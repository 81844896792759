import React, { useEffect, useState } from "react";
import { InputAdornment, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent } from "../../../../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../../../../redux/api/apiConfig";
import { CUSTOMER_FEE_GROUP, FEE, MONTHLY_FEE } from "../../../../../ApiUrl";
import SearchableSelectField from "../../../../../components/searchableSelectField";
import { handleApiErrors } from "../../../../../utils/common";

const validationSchema = yup.object({
  fee: yup
    .number("Enter Fees")
    .min(0, "Monthly Fee cannot be less than Zero")
    .required("Fees is required!"),
  group: yup.mixed().required("Group is required."),
});

const EditMonthlyFeesModal = ({
  onClose,
  isOpen,
  onSubmit,
  slabToEdit,
  isEdit,
}) => {
  const [loading, setLoading] = useState(false);

  const addClickHandler = async (values) => {
    const data = {
      ...values,
      group: values?.group?.value,
    };
    console.log(data);
    try {
      setLoading(true);
      const response = await merchantApi.post(MONTHLY_FEE, data);
      console.log(response);
      onSubmit();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const editClickHandler = async (values) => {
    const data = {
      ...values,
      group: values?.group?.value,
    };
    try {
      setLoading(true);
      const response = await merchantApi.patch(
        `${MONTHLY_FEE}${slabToEdit?.id}/`,
        data
      );
      console.log(response);
      toast.success("Monthly Fee Updated");
      onSubmit();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const fetchOptions = async (query) => {
    const url = query
      ? `${CUSTOMER_FEE_GROUP}?search=${query}`
      : `${CUSTOMER_FEE_GROUP}`; // No search parameter when query is empty

    const response = await merchantApi.get(url);
    return response?.data?.results?.map((item) => ({
      label: item?.name, // Adjust based on your API response
      value: item?.id,
    }));
  };

  const formik = useFormik({
    initialValues: {
      fee: "",
      group: null,
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      return isEdit ? editClickHandler(values) : addClickHandler(values);
    },
  });

  useEffect(() => {
    if (slabToEdit) {
      formik.setFieldValue("fee", slabToEdit?.fee);
      formik.setFieldValue("group", {
        label: slabToEdit?.group?.name,
        value: slabToEdit?.group?.id,
      });
    }
  }, [slabToEdit]);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"xs"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {isEdit ? "Edit Monthly Fee" : "Add New Monthly Fee"}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
          >
            <Stack
              spacing={1}
              direction={"column"}
              alignItems={"center"}
              mb={1}
            >
              <SearchableSelectField
                label="Group"
                name="group"
                placeholder="Type to search"
                formik={formik}
                fetchOptions={fetchOptions}
                required
              />
              <TextFieldComponent
                formik={formik}
                name={"fee"}
                type={"number"}
                label={"Fee"}
                required
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{"USD"}</InputAdornment>
                  ),
                }}
              />
            </Stack>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={onClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                {isEdit ? "Update Fee" : "Add Fee"}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default EditMonthlyFeesModal;
