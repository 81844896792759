import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Button } from "rsuite";
import SearchableSelectField from "../../../../components/searchableSelectField";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  ASSIGN_CUSTOMER_FEE_GROUP,
  SUPPLIER,
  SUPPLIER_BUS,
} from "../../../../ApiUrl";
import { handleApiErrors } from "../../../../utils/common";

const validationSchema = yup.object({
  customer_ids: yup
    .array()
    .min(1, "Must have at least 1 customer")
    .required("Customers is required"),
});

const AddCustomerList = ({ feeId, getFeeSlabs }) => {
  const [loading, setLoading] = useState(false);
  const fetchOptions = async (query) => {
    const url = query
      ? `${SUPPLIER_BUS}?search=${query}&customer_fee_group_isnull=true`
      : `${SUPPLIER_BUS}?customer_fee_group_isnull=true`; // No search parameter when query is empty

    const response = await merchantApi.get(url);
    return response?.data?.results?.map((item) => ({
      label: item?.business_name, // Adjust based on your API response
      value: item?.id,
    }));
  };

  const addClickHandler = async (values) => {
    const data = {
      customer_ids: values?.customer_ids?.map((item) => item?.value),
      fee_group_id: feeId,
    };
    console.log(data);
    try {
      setLoading(true);
      const response = await merchantApi.post(ASSIGN_CUSTOMER_FEE_GROUP, data);
      toast.success("Customers Added Successfully!");
      setLoading(false);
      formik.resetForm();
      getFeeSlabs();
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      customer_ids: [],
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      return addClickHandler(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2} direction={"column"} alignItems={"center"} mb={1}>
        <SearchableSelectField
          label="Customers"
          name="customer_ids"
          placeholder="Type to search"
          formik={formik}
          fetchOptions={fetchOptions}
          required
          multiple
        />
      </Stack>
      <Stack>
        {/* <Button
          className="btn white-btn action-button "
          onClick={onClose}
          appearance="subtle"
        >
          Cancel
        </Button> */}
        <Button
          className={`btn action-button primary_color text-white`}
          appearance="primary"
          type={"submit"}
          disabled={!formik.isValid}
          loading={loading}
        >
          Add Customers
        </Button>
      </Stack>
    </form>
  );
};

export default AddCustomerList;
