import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";
import { Routes } from "../../../routes";
import { send } from "../../../utils/xrpl_functions";
import * as API from "../../../ApiUrl";
import { RemittanceTable } from "./components";
import SelectMtoModal from "./components/addRemittance/SelectMtoModal";
import PassphraseModal from "./components/addRemittance/PassphraseModal";
import { merchantApi } from "../../../redux/api/apiConfig";
import StatusModal from "../incoming/components/statusModal/StatusModal";
import { ButtonPrimary, ButtonResetFilter, Iconify } from "../../../components";
import { format } from "date-fns";
import {
  Box,
  Divider,
  Stack,
  MenuItem,
  TextField,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import usePageTitle from "../../../hooks/usePageTitle";
import { reportTimePeriods } from "../../reports/common";
import { formatDate } from "../../../utils/common";
import { FilterDropdown } from "../../reports/components";
import { StyledDatePicker } from "../../../components/datePicker";
import * as actionTypes from "../../../redux/actionTypes";
import { AutoComplete } from "rsuite";

function OutwardRemittance() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [transactionData, setTransactionData] = useState("");
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [filters, setFilters] = useState({
    period: "",
    search: "",
    date_range_after: "",
    date_range_before: "",
    currentPage: 0,
    pageSize: 7,
  });

  const { configReducer } = useSelector((state) => state);

  const history = useHistory();

  usePageTitle("Outward Remittance", "View remittance list");

  const newRemittanceClickHander = () => {
    history.push(Routes.AddOutwardRemittance.path);
  };

  const handleClearFilters = () => {
    setFilters({
      period: "",
      search: "",
      date_range_after: "",
      date_range_before: "",
      currentPage: 0,
      pageSize: 7,
    });
  };

  // const getTrans = useCallback(async () => {
  //   setFetchTranslistLoading(true);
  //   // const { calenderValue, pageSize, currentPage, search, status } = filters;
  //   // const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
  //   // const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');
  //   merchantApi
  //     .get(`${API.REMITTANCE}`, {
  //       params: {
  //         offset: pageSize * currentPage,
  //         limit: pageSize,
  //         // search,
  //         // status: status?.id,
  //         // created_date_after,
  //         // created_date_before,
  //       },
  //     })
  //     .then((response) => {
  //       console.log("chala transaction");
  //       setFetchTranslistLoading(false);
  //       if (response.data) {
  //         setTranslist(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error?.data?.data?.message || "Something went wrong");
  //       setFetchTranslistLoading(false);
  //     });
  // }, []);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     getTrans();
  //   }, 500);
  //   return () => clearTimeout(timer);
  // }, [
  //   filters.currentPage,
  //   filters.pageSize,
  //   filters.search,
  //   filters.status,
  //   filters.calenderValue,
  //   getTrans,
  // ]);

  async function getTrans(filters) {
    setFetchTranslistLoading(true);
    try {
      const response = await merchantApi.get(`${API.REMITTANCE}`, {
        params: {
          offset: filters?.pageSize * filters?.currentPage,
          limit: filters?.pageSize,
          period: filters?.period,
          search: filters?.search,
          date_range_after: formatDate(filters?.date_range_after, "yyyy-MM-dd"),
          date_range_before: formatDate(
            filters?.date_range_before,
            "yyyy-MM-dd"
          ),
        },
      });
      setFetchTranslistLoading(false);
      setTranslist(response.data);
    } catch (error) {
      toast.error("Error getting Outgoing Transfers List");
      setFetchTranslistLoading(false);
    }
  }

  useEffect(() => {
    getTrans(filters);
  }, [filters]);

  const toggleStatusModal = () => {
    setShowStatusModal((state) => !state);
  };

  const updateStatus = (transaction) => {
    if (transaction.current_status.can_update_document) {
      history.push({
        pathname: Routes.OutwardRemittance.path + `/${transaction.id}`,
        state: { isEdit: true },
      });
    }
    setTransactionData(transaction);
    toggleStatusModal();
  };

  const updateClickHandler = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const response = await merchantApi.patch(
        `${API.REMITTANCE_OUTGOING}${transactionData.id}/`,
        {
          status,
          remarks,
        }
      );
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTrans();
    } catch (err) {
      setMtoPatchLoading(false);
      toast.error(err?.data?.data?.message || "Something went wrong");
    }
  };

  const viewDetailsHandler = (id) => {
    history.push(Routes.OutwardRemittanceBase.path + `/${id}`);
  };

  const handleChangePage = (newPage) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const handleChangeRowsPerPage = (newSize) => {
    setFilters({
      currentPage: 0,
      pageSize: newSize,
    });
  };

  return (
    <>
      <Box sx={{ padding: "0 24px 24px" }}>
        <Stack
          sx={{
            overflowX: { xs: "auto" },
            overflowY: { xs: "visible", md: "unset" },
            padding: "5px 0",
          }}
          direction={"row"}
          justifyContent={"space-between"}
          gap={1}
          mt={2}
          width={"100%"}
        >
          <Stack
            sx={{ flexDirection: "row", gap: 1 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
          >
            <Stack sx={{ minWidth: "150px", flex: 1 }}>
              <FilterDropdown
                name="period"
                type="text"
                label="Time Period"
                options={reportTimePeriods}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    period: e.target.value,
                  }));
                }}
                value={filters?.period}
                size={"small"}
                render={(period, i) => (
                  <MenuItem key={period.key} value={period.key}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <Stack sx={{ width: "176px", flex: 1 }}>
                <StyledDatePicker
                  value={filters?.date_range_after}
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setFilters((prev) => ({
                      ...prev,
                      date_range_after: new Date(
                        format(newValue, "yyyy/MM/dd")
                      ),
                    }));
                  }}
                />
              </Stack>

              <Stack sx={{ width: "176px", flex: 1 }}>
                <StyledDatePicker
                  value={filters?.date_range_before}
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setFilters((prev) => ({
                      ...prev,
                      date_range_before: new Date(
                        format(newValue, "yyyy/MM/dd")
                      ),
                    }));
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ flexDirection: "row", gap: 1, flex: 1 }}>
              <Stack sx={{ minWidth: "200px", flex: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  value={filters.search}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      search: e.target.value,
                    }))
                  }
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            {/* <Stack sx={{ minWidth: "200px", flex: 1 }}>
              <AutoComplete
                id="status"
                value={filters.status}
                onChange={(e, newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    status: newValue,
                  }));
                }}
                options={paymentStatus}
                getOptionLabel={(option) => option?.name}
                sx={{ flexGrow: 1, width: "100%" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select Status"
                      {...params}
                      size="small"
                    />
                  );
                }}
              />
            </Stack> */}
          </Stack>
          <Stack sx={{ flexDirection: "row", gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
        </Stack>
        <RemittanceTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={viewDetailsHandler}
          updateStatus={updateStatus}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          filters={filters}
        />
      </Box>

      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={transactionData?.current_status}
          // canChangeTo={
          //   isMediator
          //     ? transactionData?.current_status?.mediator_can_change_to
          //     : transactionData?.current_status?.sender_can_change_to
          // }
        />
      )}
    </>
  );
}

export default OutwardRemittance;
