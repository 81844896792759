import React, { useEffect, useState } from "react";
import { Box, InputAdornment, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent } from "../../../../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../../../../redux/api/apiConfig";
import {
  CUSTOMER_FEE_GROUP,
  FEE,
  MONTHLY_FEE,
  TRANSACTION_TYPE_FEE,
} from "../../../../../ApiUrl";
import SearchableSelectField from "../../../../../components/searchableSelectField";
import { handleApiErrors } from "../../../../../utils/common";

const validationSchema = yup.object({
  name: yup.string().required("Group Name is required!"),
});

const AddCustomerFeeGroupModal = ({
  onClose,
  isOpen,
  onSubmit,
  slabToEdit,
  isEdit,
  type,
}) => {
  const [loading, setLoading] = useState(false);

  const addClickHandler = async (values) => {
    try {
      setLoading(true);
      const response = await merchantApi.post(CUSTOMER_FEE_GROUP, values);
      console.log(response);
      onSubmit();
      toast.success("Fee Group Added Successfully!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const editClickHandler = async (values) => {
    try {
      setLoading(true);
      const response = await merchantApi.patch(
        `${CUSTOMER_FEE_GROUP}${slabToEdit?.id}/`,
        values
      );
      console.log(response);
      toast.success("Fee Group Updated Successfully!");
      onSubmit();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      return isEdit ? editClickHandler(values) : addClickHandler(values);
    },
  });

  useEffect(() => {
    if (slabToEdit) {
      formik.setFieldValue("name", slabToEdit?.name);
    }
  }, [slabToEdit]);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"xs"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {isEdit ? "Edit Customer Fee Group" : "Add New Customer Fee Group"}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
          >
            <Stack
              spacing={2}
              direction={"column"}
              alignItems={"center"}
              mb={1}
            >
              <TextFieldComponent
                formik={formik}
                name={"name"}
                type={"text"}
                label={"Group Name"}
              />
            </Stack>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={onClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                {isEdit ? "Update Fee Group" : "Add Fee Group"}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default AddCustomerFeeGroupModal;
