import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Stack, Typography, LinearProgress } from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
} from "../../../utils/common";
import { DataGrid } from "@mui/x-data-grid";
import { tableCellStyle } from "../../../utils/common";
import { Label } from "../../../components";
import { fCurrency } from "../../../utils/formatNumber";
import TableWrapper from "../../../components/TableWrapper";

export default function MonthlyFeeCollectionReportTable({
  translist,
  fetchTranslistLoading,
  filters,
  setFilters,
  client,
}) {
  const history = useHistory();

  const handleChangePage = (newPage) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const handleChangeRowsPerPage = (newSize) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: 0,
      pageSize: newSize,
    }));
  };

  const createDataRow = (transaction) => {
    // const type = transaction.type;
    return {
      id: transaction?.id,
      date: formatWithoutTimezone(transaction?.created_date, "dd MMM yyyy"),
      account:
        transaction?.debtor?.business?.name ||
        transaction?.debtor?.name ||
        "--",
      currency: transaction?.currency,
      amount: transaction?.amount,
      status: transaction?.status,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "account",
      headerName: "Customer",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "amount",
      headerName: "Monthly Fee Amount",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {fCurrency(params.formattedValue)}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "fully_settled"
                ? "complete"
                : params.formattedValue?.value === "unsettled"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
  ];

  return (
    <>
      <TableWrapper>
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          // onRowClick={(row) => {
          //   history.push({
          //     pathname: Routes.ProgrammedLiensDetailView.path,
          //     search: `?id=${row.id}`,
          //   });
          // }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters?.currentPage}
          rowCount={translist?.count}
          pageSize={filters?.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </TableWrapper>
    </>
  );
}
