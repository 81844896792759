import React, { useMemo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import {
  formatCurrency,
  rowsPerPageOptions,
  tableCellStyle,
  tableStyle,
} from "../../../../../../utils/common";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Tooltip,
  useTheme,
} from "@mui/material";
import useDeleteModal from "../../../../../../hooks/useDeleteModal";
import { DeleteModal } from "../../../../../../components/deleteModal";
import HistoryIcon from "@mui/icons-material/History";
import { fCurrency } from "../../../../../../utils/formatNumber";
import { Iconify } from "../../../../../../components";
import TableWrapper from "../../../../../../components/TableWrapper";

const InternalTransferFeesTable = ({
  slabs,
  paginateTable,
  setPaginateTable,
  fetchSlabsLoading,
  setSlabToEdit,
  setIsEdit,
  toggleAddNewSlabModal,
  deleteSlab,
  filter,
}) => {
  const theme = useTheme();

  const permission = useSelector(
    (state) =>
      state.profileReducer?.permission?.configurations?.fees
        ?.inwardRemittanceFees?.permission
  );
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {filter?.group === ""
          ? "Please select Customer Fee Group"
          : slabs?.results?.length > 0
          ? ""
          : `No Fee Assigned to ${filter?.group} Customer Group`}
      </Stack>
    );
  };

  const createDataRow = (slab) => {
    return {
      group: slab?.group?.name || "--",
      id: slab.id,
      range: `${fCurrency(slab?.amount_from)} - ${fCurrency(slab?.amount_to)}`,
      fixedCost: slab?.customer_fee_fixed
        ? fCurrency(slab?.customer_fee_fixed)
        : "--",
      percentageCharge:
        `${Number(slab?.customer_fee_percentage).toFixed(2)} %` || "--",
      actions: slab,
    };
  };

  const rows = useMemo(
    () =>
      slabs?.results?.length > 0
        ? slabs.results.map((slab) => createDataRow(slab))
        : [],
    [slabs.results]
  );

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        // flex: 1,
        // minWidth: 120,
        // maxWidth: 200,
        headerClassName: "header",
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <Stack
              // sx={{ width: "100%", height: "100%", cursor: "pointer" }}
              justifyContent={"flex-start"}
              // alignItems={"center"}
            >
              <Typography
                variant="body2"
                style={{ ...tableCellStyle, fontWeight: 600 }}
              >
                {params.formattedValue}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "group",
        headerName: "Group",
        sortable: false,
        flex: 1,
        minWidth: 300,
        // maxWidth: 250,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
        // headerAlign: 'center',
      },
      {
        field: "range",
        headerName: "Range",
        sortable: false,
        flex: 1,
        minWidth: 300,
        // maxWidth: 250,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
        // headerAlign: 'center',
      },

      {
        field: "fixedCost",
        headerName: "Fixed Cost",
        sortable: false,
        flex: 1,
        minWidth: 140,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
      {
        field: "percentageCharge",
        headerName: "Percentage Charge",
        sortable: false,
        flex: 1,
        minWidth: 200,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },

      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        // flex: 1,
        minWidth: 200,
        // maxWidth: 140,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)" }}>
              <Tooltip title="Edit" arrow>
                <IconButton
                  // disabled={!permission?.change[role]}
                  onClick={() => {
                    onEditClicked(params.formattedValue);
                  }}
                >
                  <Iconify
                    icon="raphael:edit"
                    width={22}
                    sx={{ color: theme.palette.common.accentColor }}
                  />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Delete Fee" arrow>
                <IconButton
                // disabled={!permission?.change[role]}
                onClick={() => {
                  deleteSlab(params.formattedValue?.id);
                }}
                >
                  <Iconify
                    icon="material-symbols:delete"
                    width={22}
                    sx={{ color: "red" }}
                  />
                </IconButton>
              </Tooltip> */}
            </Box>
          );
        },
      },
    ],
    []
  );

  const onEditClicked = (slab) => {
    setIsEdit(true);
    setSlabToEdit(slab);
    toggleAddNewSlabModal();
  };

  const handleChangePage = useCallback((newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  }, []);

  const handleChangeRowsPerPage = useCallback((newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  }, []);

  return (
    <div>
      <TableWrapper>
        <DataGrid
          autoHeight
          sx={tableStyle}
          density={"compact"}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchSlabsLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={slabs.count}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </TableWrapper>
    </div>
  );
};

export default InternalTransferFeesTable;
