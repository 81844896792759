import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { merchantApi } from "../../../redux/api/apiConfig";
import {
  CUSTOMER_TRANSACTION,
  MASTER_BALANCE,
  MASTER_TRANSACTION,
  SUPPLIER,
} from "../../../ApiUrl";
import { toast } from "react-toastify";
import { fCurrency } from "../../../utils/formatNumber";
import usePageTitle from "../../../hooks/usePageTitle";
import { FilterDropdown } from "../components";
import {
  ButtonResetFilter,
  ButtonSecondary,
  Iconify,
} from "../../../components";
import MonthlyPaymentFeeeReportTable from "./monthlyPaymentFeeReportTable";
import { reportTimePeriods } from "../common";

const MonthlyPaymentFeeReport = ({ client }) => {
  usePageTitle("Monthly Payment Fee Report", "View Report");
  const [transactionData, setTransactionData] = useState({});
  const [customerBalance, setCustomerBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [filters, setFilters] = useState({
    currentPage: 0,
    pageSize: 7,
  });
  const theme = useTheme();
  async function getTransactionData(filters) {
    let params = {
      offset: filters?.currentPage * filters?.pageSize,
      limit: filters?.pageSize,
    };
    setLoading(true);
    merchantApi
      .get(`${MASTER_TRANSACTION}`, {
        params,
      })
      .then((response) => {
        if (response?.data) {
          setLoading(false);
          // console.log(response?.data);
          setTransactionData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.message || "Error getting transaction list"
        );
      });
  }

  //   useEffect(() => {
  //     getTransactionData(filters);
  //   }, [filters]);

  return (
    <Box sx={{ padding: "0 24px 24px" }}>
      <Box sx={{ mt: 2 }}>
        <Stack
          sx={{
            overflowX: { xs: "auto" },
            overflowY: { xs: "visible", md: "unset" },
            padding: "5px 0",
          }}
          direction={"row"}
          justifyContent={"space-between"}
          gap={1}
          mt={2}
          width={"100%"}
        >
          <Stack
            sx={{ flexDirection: "row", gap: 1 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
          >
            <Stack sx={{ minWidth: "150px", flex: 1 }}>
              <FilterDropdown
                name="period"
                type="text"
                label="Time Period"
                options={reportTimePeriods}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    period: e.target.value,
                  }));
                }}
                value={filters?.period}
                size={"small"}
                render={(period, i) => (
                  <MenuItem key={period.key} value={period.key}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>

            <Stack sx={{ minWidth: "200px", flex: 1 }}>
              <Autocomplete
                id="status"
                value={filters.status}
                onChange={(e, newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    status: newValue,
                  }));
                }}
                options={[]}
                getOptionLabel={(option) => option?.name}
                sx={{ flexGrow: 1, width: "100%" }}
                renderInput={(params) => {
                  return (
                    <TextField placeholder="Client" {...params} size="small" />
                  );
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", gap: 1 }}>
            <ButtonResetFilter />
            <ButtonSecondary>
              <Iconify
                icon={"vscode-icons:file-type-excel"}
                sx={{ width: "20px" }}
              />{" "}
              Export
            </ButtonSecondary>
          </Stack>
        </Stack>
        <MonthlyPaymentFeeeReportTable
          translist={transactionData}
          filters={filters}
          setFilters={setFilters}
          fetchTranslistLoading={loading}
          client={client}
        />
      </Box>
    </Box>
  );
};

export default MonthlyPaymentFeeReport;
