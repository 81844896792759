import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TransactionStatementTable from "./transactionStatementTable";
import { merchantApi } from "../../../../redux/api/apiConfig";
import {
  CUSTOMER_TRANSACTION,
  INCOMING_TRANSFER,
  SUPPLIER,
} from "../../../../ApiUrl";
import { toast } from "react-toastify";
import { fCurrency } from "../../../../utils/formatNumber";

const CustomerTransactionStatement = ({ client }) => {
  const [transactionData, setTransactionData] = useState({});
  const [customerBalance, setCustomerBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [filters, setFilters] = useState({
    currentPage: 0,
    pageSize: 7,
  });
  const theme = useTheme();
  async function getTransactionData(filters) {
    let params = {
      offset: filters?.currentPage * filters?.pageSize,
      limit: filters?.pageSize,
    };
    setLoading(true);
    merchantApi
      .get(`${CUSTOMER_TRANSACTION}${client?.id}/?status=Complete`, {
        params,
      })
      .then((response) => {
        if (response?.data) {
          setLoading(false);
          // console.log(response?.data);
          setTransactionData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.message || "Error getting transaction list"
        );
      });
  }

  async function getCustomerBalance() {
    setLoadingBalance(true);
    merchantApi
      .get(`${SUPPLIER}${client?.id}/balance/`)
      .then((response) => {
        if (response?.data) {
          setLoadingBalance(false);
          setCustomerBalance(response?.data?.data?.balance);
        }
      })
      .catch((error) => {
        setLoadingBalance(false);
        toast.error("Error getting customer balance");
      });
  }

  useEffect(() => {
    getTransactionData(filters);
  }, [filters]);

  useEffect(() => {
    getCustomerBalance();
  }, []);
  return (
    <Box sx={{ mt: 2 }}>
      <Grid container sx={{ my: 3 }}>
        <Grid item md={4}>
          <Box
            sx={{
              p: 3,
              borderRadius: "7px",
              backgroundColor: theme.palette.common.accentColor,
            }}
            direction={"column"}
            gap={1}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                fontWeight: "600",
                fontSize: "25px",
                mb: 1,
              }}
            >
              Wallet Balance
            </Typography>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderStyle: "solid", borderColor: "#fff" }}
            />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 2,
                mt: 2,
              }}
            >
              <Typography
                variant="p"
                sx={{ color: "#fff", fontSize: "45px", fontWeight: 600 }}
              >
                {loadingBalance ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  fCurrency(customerBalance)
                )}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <TransactionStatementTable
        translist={transactionData}
        filters={filters}
        setFilters={setFilters}
        fetchTranslistLoading={loading}
        client={client}
      />
    </Box>
  );
};

export default CustomerTransactionStatement;
