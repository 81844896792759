import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { capitalize } from "lodash";
import React, { useState } from "react";
import SettleContractModal from "./settleContract";
import PaySupplier from "./paySupplier";

const AddNewTransactionModal = ({
  onClose,
  isOpen,
  lenderList,
  details,
  onSuccess,
}) => {
  const [currentTab, setCurrentTab] = useState("settle a contract");

  const PREVIEW_TABS = [
    {
      value: "settle a contract",
      // icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: (
        <SettleContractModal
          onClose={onClose}
          lenderList={lenderList}
          onSuccess={onSuccess}
          contractId={details?.id}
        />
      ),
    },
    {
      value: "pay a supplier",
      // icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: (
        <PaySupplier
          onClose={onClose}
          details={details}
          onSuccess={onSuccess}
          contractId={details?.id}
        />
      ),
    },
  ];
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"sm"}
      onClose={onClose}
      scroll={"body"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        Create New Transaction
      </DialogTitle>
      <DialogContent
        sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
      >
        <Stack
          justifyContent={"space-between"}
          alignItems={{ md: "center", xs: "start" }}
          direction={{ md: "row", xs: "column" }}
          spacing={2}
        >
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            sx={{ background: "#f4f4f4", width: "100%" }}
          >
            {PREVIEW_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={capitalize(tab.value)}
                iconPosition="start"
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Stack>
        {PREVIEW_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return (
            isMatched && (
              <Box key={tab.value} sx={{ mt: 2 }}>
                {tab.component}
              </Box>
            )
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default AddNewTransactionModal;
