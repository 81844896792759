import React, { useEffect, useState } from "react";
import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldSelect } from "../../../../components";
import { toast } from "react-toastify";
import { merchantApi } from "../../../../redux/api/apiConfig";
import * as API from "../../../../ApiUrl";

const numricRegex = /(?=.*[0-9])/;
const validationSchema = yup.object({
  status: yup.string("Select Status").required("Status is required"),
});

const UpdateClaimStatusModal = ({ onClose, isOpen, data, id, onSuccess }) => {
  //   console.log("status data", data);
  const theme = useTheme();
  const [loading, setloading] = useState(false);
  const [kycStatus, setKycStatus] = useState(
    data?.map((item) => ({ name: item?.display, value: item?.value }))
  );

  const onSubmit = async () => {
    const data = { ...formik.values };
    try {
      setloading(true);
      const url =
        data?.status === "approved"
          ? `${API.INCOMING_TRANSFER_CLAIM}${id}/approve/`
          : `${API.INCOMING_TRANSFER_CLAIM}${id}/reject/`;
      const respose = await merchantApi.post(url);
      toast.success("Status Updated successfully");
      onSuccess();
    } catch (error) {
      setloading(false);
      toast.error(
        error?.response?.data?.message || "Error while updating status"
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
    validateOnMount: true,
  });

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"sm"}
        onClose={onClose}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontSize: "24px !important",
            color: theme.palette.common.accentColor,
          }}
        >
          Update Claim Status
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              paddingTop: "0px !important",
              paddingBottom: "0px !important",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <TextFieldSelect
                formik={formik}
                name={"status"}
                placeholder="Select Status"
                label={"Status"}
                options={kycStatus}
                render={(role) => (
                  <MenuItem value={role.value}>{role.name}</MenuItem>
                )}
              />
              {formik?.values?.status === "approved" && (
                <Box sx={{ mt: 1 }}>
                  <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                    <b>NOTE:</b> If the status is changed to 'Approved', all
                    other claims will automatically be marked as 'Rejected'.
                  </Typography>
                </Box>
              )}
            </Box>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={() => {
                  formik.resetForm();
                  onClose();
                }}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                Update Status
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default UpdateClaimStatusModal;
