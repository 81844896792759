import React, { useState } from "react";
// form
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
// @mui
import {
  Typography,
  Paper,
  Stack,
  SpeedDial,
  SpeedDialAction,
  Box,
  LinearProgress,
  Card,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import { _socials } from "../../../utils/common";
import { Iconify, PreviewContainer, PreviewItem } from "../../../components";
import SharePaymentDetails from "./SharePaymentDetails";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

//  ${info.lei_code && `LEI: ${info.lei_code},  `}
export default function PaymentMethodDetails({
  info,
  isLoading,
  setEditAccountDetails,
  toggleShowEditAccountDetails,
}) {
  const { user } = useSelector((state) => state.profileReducer);
  const theme = useTheme();

  console.log(info);

  const { copy } = useCopyToClipboard();
  let mailSubject = `Bank Account Details`;
  let copyContent = `Bank Account Details:
 Account Holder Name: ${info?.name},
 Beneficiary's Account Number: ${info?.account_no},
 Beneficiary's Bank: ${info?.branch},
 SWIFT Code: ${info?.swift_code},
 Beneficiary's ABA/Routing Number: ${info?.aba_number},
 Beneficiary's Address: ${info?.address},
 Beneficiary's Bank Address: ${info?.bank_address}  `;
  let url = encodeURIComponent(copyContent);

  const handleCopy = (text) => {
    copy(text);
  };

  const shareClickHandler = (type) => {
    switch (type) {
      case "copy":
        handleCopy(copyContent);
        break;
      case "whatsapp": {
        window.open(`https://wa.me/?text=${url}`, "_blank");
        break;
      }
      case "email": {
        window.open(`mailto:?subject=${mailSubject}&body=${url}`, "_blank");
        break;
      }

      default:
        break;
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <Card
        sx={{
          width: 1,
          boxShadow: 0,
          color: theme.palette["warning"].darker,
          bgcolor: theme.palette["warning"].lighter,
          position: "relative",
          minHeight: '410px'
        }}
      >
        <Stack spacing={4} sx={{ p: 3 }}>
          <Typography variant="h4">Bank Account Details</Typography>
          {isLoading ? (
            <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: "30px",
                mb: "20px !important"
              }}
            >
              <Item
                key={"name"}
                title={"Account Holder Name"}
                content={info?.name}
              />
              <Item
                key={"ac_no"}
                title={"Beneficiary's Account Number"}
                content={info?.account_no}
              />
              <Item
                key={"branch"}
                title={"Beneficiary's Bank"}
                content={info?.branch}
              />
              <Item
                key={"swift"}
                title={"SWIFT Code"}
                content={info?.swift_code}
              />
              <Item
                key={"aba_number"}
                title={"Beneficiary's ABA/Routing Number"}
                content={info?.aba_number}
              />
              <Item
                key={"address"}
                title={"Beneficiary's Address"}
                content={info?.address}
              />
              <Item
                key={"bank_address"}
                title={"Beneficiary's Bank Address"}
                content={info?.bank_address}
              />
              <Item key={"currency"} title={"Currency"} content={"USD"} />
            </Box>
          )}
          <Typography sx={{mt: 4, maxWidth: '70%'}}>
            <b>Special Instructions:</b> Please always put remarks as "{" "}
            <b>ZM 5 OCW Buckzy Payments Inc #42091</b> " for quick credit to
            account.
          </Typography>
        </Stack>

        <Iconify
          icon={"hugeicons:bank"}
          sx={{
            width: 220,
            height: 220,
            opacity: 0.3,
            position: "absolute",
            right: theme.spacing(0),
            top: theme.spacing(0),
            zIndex: 0,
          }}
        />
        <SpeedDial
          direction={"up"}
          ariaLabel="Share payment method info"
          icon={<Iconify icon="eva:share-fill" />}
          sx={{
            "& .MuiSpeedDial-fab , & .MuiSpeedDial-fab:hover": {
              width: 48,
              height: 48,
              backgroundColor: "#231F3A",
            },
            position: "absolute",
            bottom: "20px",
            right: "10px",
          }}
        >
          {_socials.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={<Iconify icon={action.icon} sx={{ color: action.color }} />}
              tooltipTitle={action.name}
              tooltipPlacement="left"
              FabProps={{ color: "default" }}
              onClick={() => shareClickHandler(action.value)}
            />
          ))}
        </SpeedDial>
        <Tooltip title="Edit Bank Details">
          <IconButton
            sx={{
              width: 48,
              height: 48,
              backgroundColor: "#231F3A !important",
              position: "absolute",
              bottom: "20px",
              right: "80px",
            }}
            onClick={() => {
              setEditAccountDetails(info);
              toggleShowEditAccountDetails();
            }}
          >
            <Iconify icon="raphael:edit" sx={{ color: "#fff" }} />
          </IconButton>
        </Tooltip>
      </Card>
    </Stack>
  );
}

// ----------------------------------------------------------------------

function Item({ title, content, type = "" }) {
  return (
    <>
      <Stack
        sx={{ cursor: "default" }}
        direction={"column"}
        spacing={0}
        alignItems={"flex-start"}
      >
        <Typography variant="overline">{title}</Typography>
        <Stack sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Typography variant="body2">{content || "--"}</Typography>
        </Stack>
      </Stack>
    </>
  );
}
