import React, { useState } from "react";
import {
  Iconify,
  Label,
  PreviewContainer,
  PreviewItem,
} from "../../../../components";
import { Box, Stack } from "@mui/material";
import UpdateInvoiceReceiptInfo from "../updateInvoiceReceiptInfo/updateInvoiceReceiptInfo";
import { Button } from "rsuite";
import { formatWithoutTimezone } from "../../../../utils/common";
import { fCurrency } from "../../../../utils/formatNumber";
import BroadcastUnmappedModal from "../../../../components/broadcastUnmappedModal/broadcastUnmappedModal";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { toast } from "react-toastify";
import { INCOMING_TRANSFER } from "../../../../ApiUrl";

const InvoiceReceiptInfo = ({ details, getTransferDetail }) => {
  const [showBroadcast, setShowBroadcast] = useState(false);
  const [loading, setLoading] = useState(false);
  const businessDetails = (
    <>
      <PreviewItem
        title={"Date of Receipt"}
        content={
          details?.created_date
            ? formatWithoutTimezone(details?.created_date, "dd MMM yyyy")
            : "--"
        }
      />
      <PreviewItem title={"Paid By"} content={details?.paid_by} />
      <PreviewItem title={"COBO"} content={details?.cobo?.name} />
      <PreviewItem title={"Amount"} content={fCurrency(details?.amount)} />
      <PreviewItem
        title={"Settlement Status"}
        content={
          <>
            <Label
              variant={"ghost"}
              color={
                details?.settlement_status?.value === "fully_settled"
                  ? "complete"
                  : details?.settlement_status?.value === "unsettled"
                  ? "rejected"
                  : "application submitted"
              }
              sx={{ textTransform: "capitalize" }}
            >
              {details?.settlement_status?.display}
            </Label>
          </>
        }
      />
    </>
  );

  async function cancelBroadcastHandler() {
    setLoading(true);
    merchantApi
      .post(`${INCOMING_TRANSFER}${details?.id}/broadcast_status/`, {
        status: "cancelled",
      })
      .then((response) => {
        if (response?.data) {
          setLoading(false);
          toast.success("Broadcast Cancelled");
          getTransferDetail();
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.message || "Error Cancelling Broadcast!"
        );
      });
  }
  return (
    <>
      <Box sx={{ mt: 3, mb: 3 }}>
        {(details?.settlement_status?.value === "unsettled" ||
          details?.settlement_status?.value === "under_claim_request") && (
          <Stack direction={"row"} justifyContent={"flex-end"} sx={{ mb: 3 }}>
            {details?.settlement_status?.value === "unsettled" && (
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"button"}
                style={{
                  maxWidth: "300px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                // disabled={!formik.isValid}
                // loading={loading}
                onClick={() => setShowBroadcast(true)}
              >
                <Iconify icon="mdi:broadcast" width={22} />
                Broadcast For Unmapped
              </Button>
            )}
            {details?.settlement_status?.value === "under_claim_request" && (
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"button"}
                style={{
                  maxWidth: "300px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                // disabled={!formik.isValid}
                loading={loading}
                onClick={cancelBroadcastHandler}
              >
                <Iconify icon="mdi:broadcast-off" width={22} />
                Cancel Broadcast
              </Button>
            )}
          </Stack>
        )}
        <PreviewContainer>{businessDetails}</PreviewContainer>
        <UpdateInvoiceReceiptInfo
          details={details}
          getTransferDetail={getTransferDetail}
        />
      </Box>
      <BroadcastUnmappedModal
        isOpen={showBroadcast}
        onClose={() => setShowBroadcast((prev) => !prev)}
        transferId={details?.id}
        onSuccess={getTransferDetail}
      />
    </>
  );
};

export default InvoiceReceiptInfo;
