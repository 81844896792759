import React, { useState, useRef, useEffect, useCallback } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { ButtonPrimary, ButtonSecondary, Iconify } from "../../../components";
import { ViewDetails } from "./viewDetails/ViewDetails";
import { DocumentDetails } from "./documentDetails";
import { PaymentDetailsTable } from "./paymentDetails";
import StatusHistory from "./statusHistory";
import { capitalize } from "lodash";
import { Box, Tab, Tabs, Stack, Skeleton, useTheme } from "@mui/material";
import back from "../../../assets/img/icons/back.svg";
import ReactToPrint from "react-to-print";
import { useSelector } from "react-redux";
import PreviewPDF from "../../../components/previewPDF/PreviewPDF";
import { toast } from "react-toastify";
import { merchantApi } from "../../../redux/api/apiConfig";
import {
  REMITTANCE,
  REMITTANCE_INCOMING,
  REMITTANCE_OUTGOING,
  REMITTANCE_UPLOAD,
} from "../../../ApiUrl";
import StatusModal from "../incoming/components/statusModal/StatusModal";
import usePageTitle from "../../../hooks/usePageTitle";

const RemittanceDetails = ({ type }) => {
  const [currentTab, setCurrentTab] = useState("details");
  const [transaction, setTransaction] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const { walletsReducer, profileReducer } = useSelector((state) => state);
  const { coins = [] } = walletsReducer;
  const theme = useTheme();

  let componentRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const isInward = location.pathname.includes("inward");

  usePageTitle("Remittance Details");

  const getTransasction = useCallback(async () => {
    try {
      setLoading(true);
      const response = await merchantApi.get(`${REMITTANCE}${id}/`);
      setTransaction(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  }, [id, isInward]);

  useEffect(() => {
    getTransasction();
    if (location?.state?.isEdit) {
      setCurrentTab("documents");
    }
  }, [id, getTransasction, location]);

  const PREVIEW_TABS = [
    {
      value: "details",
      icon: <Iconify icon={"ic:round-account-box"} width={20} height={20} />,
      component: (
        <ViewDetails
          ref={componentRef}
          transaction={transaction}
          isInward={isInward}
        />
      ),
    },
    {
      value: "documents",
      icon: <Iconify icon={"ion:documents"} width={20} height={20} />,
      component: (
        <DocumentDetails
          transaction={transaction}
          getTransasction={getTransasction}
          isInward={isInward}
        />
      ),
    },
    {
      value: "status history",
      icon: (
        <Iconify
          icon={"material-symbols:history-rounded"}
          width={20}
          height={20}
        />
      ),
      component: (
        <StatusHistory
          statuses={transaction?.status_history}
          type={isInward ? "inward" : "outward"}
        />
      ),
    },
    // {
    //   value: "payments",
    //   icon: (
    //     <Iconify
    //       icon={"material-symbols:credit-card-outline"}
    //       width={20}
    //       height={20}
    //     />
    //   ),
    //   component: (
    //     <PaymentDetailsTable
    //       translist={transaction?.transaction}
    //       fetchTranslistLoading={false}
    //       currency={transaction?.send_currency}
    //     />
    //   ),
    // },
  ];

  const backClickHandler = () => {
    history.goBack();
  };

  const toggleStatusModal = () => {
    setShowStatusModal((state) => !state);
  };

  const updateClickHandler = async (status, remarks, document) => {
    if (isInward) {
      if (document) {
        updateStatusWithDocument(status, remarks, document);
      } else {
        updateStatusCall(status, remarks);
      }
    } else {
      updateStatusCall(status, remarks);
    }
  };

  const updateStatusWithDocument = async (status, remarks, documents) => {
    try {
      setMtoPatchLoading(true);
      let fileData = new FormData();
      fileData.append("documents", documents);
      fileData.append("remarks", remarks);
      fileData.append("status", status);

      const response = await merchantApi.patch(
        `${REMITTANCE_UPLOAD}${transaction.id}/`,
        fileData
      );
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTransasction();
    } catch (error) {
      setMtoPatchLoading(false);
      toast.error(error?.data?.data?.message || "Something went wrong");
    }
  };

  const updateStatusCall = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const URL = isInward ? REMITTANCE_INCOMING : REMITTANCE_OUTGOING;
      const response = await merchantApi.patch(`${URL}${transaction.id}/`, {
        status,
        remarks,
      });
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTransasction();
    } catch (err) {
      setMtoPatchLoading(false);
      toast.error(err?.data?.data?.message || "Something went wrong");
    }
  };

  let canChangeStatus = false;
  let canChangeTo = [];

  // if (isMediator) {
  //   canChangeStatus =
  //     transaction?.current_status?.mediator_can_change_to.length !== 0;
  //     canChangeTo = transaction?.current_status?.mediator_can_change_to
  // } else {
  //   if (isInward) {
  //     canChangeStatus =
  //       transaction?.current_status?.receiver_can_change_to.length !==
  //       0;
  //       canChangeTo = transaction?.current_status?.receiver_can_change_to
  //   } else {
  //     canChangeStatus =
  //       transaction?.current_status?.sender_can_change_to.length !== 0;
  //       canChangeTo = transaction?.current_status?.sender_can_change_to
  //   }
  // }

  // const updateStatusAllowed =
  //   permission?.remittances?.outward?.allremittance?.permission
  //     ?.updateAllStatus[role];

  // console.log(canChangeStatus, updateStatusAllowed)

  const updateStatus = () => {
    if (transaction.current_status.can_update_document) {
      setCurrentTab("documents");
      return;
    }
    toggleStatusModal();
  };

  return (
    <Box sx={{ padding: "24px " }}>
      {loading ? (
        <Skeleton variant="rounded" animation={"wave"} height={1500} />
      ) : (
        <>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <span onClick={backClickHandler} className="back mb-4">
              <img src={back} alt="back" />
              <span>Back</span>
            </span>
          </Stack>

          <Stack
            justifyContent={"space-between"}
            alignItems={{ md: "center", xs: "start" }}
            direction={{ md: "row", xs: "column" }}
            spacing={2}
          >
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              sx={{
                background: theme.palette.common.lightAccentColor,
                width: "100%",
                borderRadius: "7px",
                "& .MuiTab-labelIcon": {
                  color: theme.palette.common.accentColor,
                },
              }}
            >
              {PREVIEW_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  label={capitalize(tab.value)}
                  iconPosition="start"
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
            {/* <Stack spacing={2} direction={"row"}>
              {currentTab === "details" && transaction ? (
                <>
                  <ReactToPrint
                    trigger={() => (
                      <ButtonSecondary>
                        <Iconify sx={{ mr: 1 }} icon={"eva:printer-outline"} />
                        Print
                      </ButtonSecondary>
                    )}
                    content={() => componentRef.current}
                    documentTitle={transaction?.reference_number}
                  />

                  <PreviewPDF
                    coins={coins}
                    transaction={transaction}
                    isInward={isInward}
                  />
                </>
              ) : (
                ""
              )}
              {!canChangeStatus || !updateStatusAllowed ? (
                ""
              ) : (
                <ButtonPrimary onClick={updateStatus}>
                  Update Status
                </ButtonPrimary>
              )}
            </Stack> */}
          </Stack>
          {transaction &&
            PREVIEW_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
      )}

      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={transaction?.current_status}
          canChangeTo={canChangeTo}
        />
      )}
    </Box>
  );
};

export default RemittanceDetails;
