import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Button } from "rsuite";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Paper,
} from "@mui/material";
import { formatWithoutTimezone } from "../../utils/common";
import Scrollbar from "../Scrollbar";
import TableSkeleton from "../TableSkeleton";
import TableNoData from "../TableNoData";

const FeeHistoryModal = ({
  onClose,
  isOpen,
  text = "",
  group,
  feeHistoryData,
  loading,
}) => {
  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  function createData(
    id,
    date,
    actions,
    range,
    customer_fee_fixed,
    customer_fee_percentage
  ) {
    return {
      id,
      date: formatWithoutTimezone(date, "dd MMM yyyy HH:mm a"),
      actions,
      range,
      customer_fee_fixed,
      customer_fee_percentage,
    };
  }

  const rows = feeHistoryData?.map((item) =>
    createData(
      item?.id,
      item?.created_date,
      item?.actions,
      item?.range,
      item?.customer_fee_fixed,
      item?.customer_fee_percentage
    )
  );

  const isNotFound = feeHistoryData?.length === 0;

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"lg"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>{`${group ? group : ""} Fee History`}</DialogTitle>
        <DialogContent sx={{ paddingBottom: "0 !important" }}>
          <TableContainer
            sx={{
              position: "relative", // Keeps it within the dialog layout
              maxHeight: "500px", // Add maximum height for vertical scrolling
              overflowY: "auto", // Enable vertical scrolling
              overflowX: "auto", // Enable horizontal scrolling if needed
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">ID</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Action</TableCell>
                  <TableCell align="left">Range</TableCell>
                  <TableCell align="left">Fixed Cost</TableCell>
                  <TableCell align="left">Percentage Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : rows?.length > 0 ? (
                  rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{row.id || "--"}</TableCell>
                      <TableCell align="left">{row.date || "--"}</TableCell>
                      <TableCell align="left">
                        <ul>
                          {row.actions?.map((item) => (
                            <li>
                              <Typography style={{ fontSize: "12px" }}>
                                {item}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                      </TableCell>
                      <TableCell align="left">{row?.range || "--"}</TableCell>
                      <TableCell align="left">
                        {row?.customer_fee_fixed || "--"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.customer_fee_percentage || "--"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableNoData isNotFound={isNotFound} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <div></div>
            <Button
              className={`btn action-button primary_color text-white`}
              appearance="primary"
              type={"submit"}
              onClick={onClose}
              loading={loading}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FeeHistoryModal;
