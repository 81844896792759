import React from "react";
import { Box } from "@material-ui/core";
import { PreviewContainer, PreviewItem } from "../../../../components";
import {
  formatCurrency,
  formatWithoutTimezone,
} from "../../../../utils/common";
import { Stack } from "@mui/material";
import Investvertex from "../../../../assets/img/icons/InvestvertexWhite.png";
// import logo from '../../../../assets/img/logo-stb.png'
import Image from "../../../../components/image";
import { fCurrency } from "../../../../utils/formatNumber";
import ipusalogo from "../../../../assets/img/ip_transparent_logo.png";

export const ViewDetails = React.forwardRef(
  ({ transaction, backClickHandler, isInward }, ref) => {
    const {
      reference_number,
      sender,
      receiver,
      receiver_account,
      send_amount,
      send_currency,
      receive_amount,
      receiver_total_amount,
      receive_currency,
      created_date,
      created_user,
      deposit_type,
      purpose,
      type,
      receipt_mto,
      sender_commission,
      receiver_commission,
      stb_fee,
      total_fee,
      customer_paid_amount,
      exchange_rate,
    } = transaction;

    let remittanceContent = (
      <>
        <PreviewItem
          title={"Payment Order Number"}
          content={reference_number}
        />
        <PreviewItem
          title={"Remittance Amount"}
          content={fCurrency(send_amount)}
        />
        <PreviewItem title={"Sender Fee"} content={fCurrency(0)} />
        <PreviewItem title={"Beneficiary Fee"} content={fCurrency(0)} />
        <PreviewItem title={"Other Fee"} content={fCurrency(0)} />
        <PreviewItem title={"Total Fee"} content={fCurrency(0)} />
        {/* <PreviewItem
        title={'Total Deposit Amount'}
        content={formatCurrency(customer_paid_amount, send_currency.decimal_place, send_currency.symbol)}
      /> */}
        <PreviewItem
          title={"Amount to Payout"}
          content={fCurrency(receive_amount)}
        />
        <PreviewItem
          title={"Sender Country"}
          content={transaction?.sender_country?.name}
        />
        <PreviewItem
          title={"Funding Source"}
          content={transaction?.funding_source?.title}
        />
        <PreviewItem
          title={"Payment Reference"}
          content={transaction?.payment_reference}
        />
        <PreviewItem title={"Purpose of Remittance"} content={purpose?.name} />
        <PreviewItem title={"Purpose Description"} content={purpose?.name} />
        <PreviewItem
          title={"Relationship"}
          content={transaction?.relationship?.display}
        />
        {/* <PreviewItem
          title={"Conversion Rate"}
          content={`1 ${send_currency.symbol} = ${exchange_rate} ${receive_currency.symbol}`}
        /> */}
        <PreviewItem
          title={"Date"}
          content={formatWithoutTimezone(created_date, "dd MMM yyyy HH:mm a")}
        />
        <PreviewItem title={"Created By"} content={created_user} />
      </>
    );

    let senderContent = (
      <>
        <PreviewItem title={"Business Name"} content={sender?.business_name} />
        <PreviewItem
          title={"Primary Contact Name"}
          content={sender?.primary_contact_name}
        />
      </>
    );

    let recipientContent = (
      <>
        <PreviewItem
          title={"Business Name"}
          content={receiver?.business_name}
        />
        <PreviewItem
          title={"Primary Contact Name"}
          content={receiver?.primary_contact_name}
        />
      </>
    );
    const {
      name,
      account_no,
      lei_code,
      branch,
      iban,
      swift_code,
      transit_number,
      aba_number,
      address,
      ifsc_code,
    } = receiver?.finance_account[0]?.bank;
    let financialDetails = (
      <>
        <PreviewItem
          title={"Account Holder"}
          content={receiver?.finance_account[0]?.account_holder_name}
        />
        <PreviewItem
          title={"Account Type"}
          content={receiver?.finance_account[0]?.account_type?.replace(
            /_/g,
            ""
          )}
        />
        <PreviewItem
          title={"Country"}
          content={receiver?.finance_account[0]?.country?.name}
        />
        <PreviewItem title={"Bank Name"} content={name} />
        <PreviewItem title={"Branch"} content={branch} />{" "}
        <PreviewItem title={"Account Number"} content={account_no} />{" "}
        <PreviewItem title={"LEI Code"} content={lei_code} />{" "}
        <PreviewItem title={"IBAN"} content={iban} />{" "}
        <PreviewItem title={"SWIFT BIC Code"} content={swift_code} />
        <PreviewItem title={"Transit Number"} content={transit_number} />
        <PreviewItem title={"ABA Routing Number"} content={aba_number} />
        <PreviewItem title={"IFSC Code"} content={ifsc_code} />
        <PreviewItem title={"Address"} content={address} />
      </>
    );

    return (
      <>
        <Box ref={ref} sx={{ marginTop: "1.5rem", padding: "1rem" }}>
          <Stack spacing={3} direction={"column"}>
            <Stack
              sx={{
                backgroundColor: "#cfd9ff",
                padding: "1rem",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                src={ipusalogo}
                alt="IPUSA"
                sx={{ width: "100px", height: "auto" }}
              />
            </Stack>
            <PreviewContainer title={"Remittance Details"}>
              {remittanceContent}
            </PreviewContainer>
            <PreviewContainer title={"Sender Details"}>
              {senderContent}
              <PreviewItem
                title={"Phone"}
                content={sender?.primary_contact_no}
              />
              <PreviewItem title={"City"} content={sender?.city?.name} />
              <PreviewItem
                title={"Province"}
                content={sender?.province?.name}
              />
              <PreviewItem title={"Country"} content={sender?.country?.name} />
              <PreviewItem title={"Address"} content={sender?.address} />
            </PreviewContainer>
            <PreviewContainer title={"Recipient Details"}>
              {recipientContent}
              <PreviewItem
                title={"Phone"}
                content={receiver?.primary_contact_no}
              />
              <PreviewItem title={"City"} content={receiver?.city?.name} />

              <PreviewItem
                title={"Province"}
                content={receiver?.province?.name}
              />
              <PreviewItem
                title={"Country"}
                content={receiver?.country?.name}
              />
              <PreviewItem title={"Address"} content={receiver?.address} />
            </PreviewContainer>
            <PreviewContainer title={"Financial Details"}>
              {financialDetails}
            </PreviewContainer>
          </Stack>
        </Box>
      </>
    );
  }
);
