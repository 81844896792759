import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Stack, Typography, LinearProgress, Button } from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
} from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { tableCellStyle } from "../../../../utils/common";
import { Iconify, Label } from "../../../../components";
import { Routes } from "../../../../routes";
import { fCurrency } from "../../../../utils/formatNumber";

export default function PaymentClaimsTable({
  translist,
  fetchTranslistLoading,
  filters,
  setFilters,
  setShowClaimDetail,
  setShowClaimDocument,
  setClaimDocuments,
  setClaimDetails,
  getClaimsData,
}) {
  const history = useHistory();

  const handleChangePage = (newPage) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const handleChangeRowsPerPage = (newSize) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: 0,
      pageSize: newSize,
    }));
  };

  const handleCellClick = (e, params) => {
    e.stopPropagation(); // Prevent the row click event
    setClaimDocuments(params);
    setShowClaimDocument(true);
  };

  const createDataRow = (transaction) => {
    // const type = transaction.type;
    return {
      id: transaction?.id,
      date: formatWithoutTimezone(transaction?.created_date, "dd MMM yyyy"),
      customer: transaction?.customer,
      claim: transaction?.documents,
      status: transaction?.status,
      data: transaction,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "Claim ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "date",
      headerName: "Date of Claim",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue?.name}
              </Typography>
              {/* <Typography
                variant="suubtitle"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue?.email}
              </Typography> */}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "claim",
      headerName: "Claim Documents",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"}>
            <Button
              variant="contained" // Choose between "contained", "outlined", or "text"
              color="primary"
              type="button"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "14px",
              }}
              onClick={(e) => handleCellClick(e, params.formattedValue)}
            >
              <Iconify icon="solar:documents-bold" width={20} />
              See Documents
            </Button>
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: "Claim Status",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "approved"
                ? "complete"
                : params.formattedValue?.value === "rejected"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          // height: 825,
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          onRowClick={(row) => {
            setClaimDetails(row?.row?.data);
            setShowClaimDetail(true);
          }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination={false}
          hideFooterPagination={true}
          // paginationMode="server"
          // rowsPerPageOptions={rowsPerPageOptions}
          // page={filters.currentPage}
          // rowCount={translist.count}
          // pageSize={filters.pageSize}
          // onPageChange={(newPage) => handleChangePage(newPage)}
          // onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}
