import React, { useState, useEffect, useCallback } from "react";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { AddNewSlabModal } from "../feesComponents";
import "rsuite/dist/styles/rsuite-default.css";
import { toast } from "react-toastify";
import * as API from "../../../../ApiUrl";
import usePageTitle from "../../../../hooks/usePageTitle";
import { Box, Divider, MenuItem, Stack, Button } from "@mui/material";
import {
  ButtonPrimary,
  ButtonResetFilter,
  Iconify,
} from "../../../../components";
import { useSelector } from "react-redux";
import FeeHistoryModal from "../../../../components/feeHistoryModal/FeeHistoryModal";
import AddEditTransactionTypeFeeModal from "../feesComponents/addEditTransactionTypeFeeModal/addEditTransactionTypeFeeModal";
import useDeleteModal from "../../../../hooks/useDeleteModal";
import { DeleteModal } from "../../../../components/deleteModal";
import { FilterDropdown } from "../../../reports/components";
import ContractPaymentFeesTable from "./ContractPaymentFeesTable";

// import searchIcon from '../../../assets/img/icons/searchIcon.svg';

// import { Form, InputGroup } from '@themesberg/react-bootstrap';

const ContractPaymentFees = () => {
  const [slabs, setSlabs] = useState([]);
  const [fetchSlabsLoading, setFetchSlabsLoading] = useState(false);
  const [showAddSlabModal, setShowAddSlabModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [slabToEdit, setSlabToEdit] = useState(null);
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: "",
  });
  const [showFeeHistoryModal, setShowFeeHistoryModal] = useState(false);
  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();
  const [filters, setFilters] = useState({
    group: "",
  });
  const [customerGroup, setCustomerGroup] = useState(null);
  const [feeHistoryData, setFeeHistoryData] = useState([]);
  const [feesHistoryLoading, setFeesHistoryLoading] = useState(false);

  const toggleFeeHistoryModal = () => {
    setShowFeeHistoryModal((prevState) => !prevState);
  };

  usePageTitle("Contract Payment Fees", "View Contract Payment Fees List");

  useEffect(() => {
    console.log(slabToEdit);
  }, [slabToEdit]);

  const toggleAddNewSlabModal = () => {
    if (showAddSlabModal) {
      getFeeSlabs(filters);
      setIsEdit(false);
      setSlabToEdit(null);
    }
    setShowAddSlabModal((prevState) => !prevState);
  };

  const deleteSlab = async (id) => {
    setFetchSlabsLoading(true);
    try {
      const response = await merchantApi.delete(
        `${API.TRANSACTION_TYPE_FEE}${id}/`
      );
      console.log(response);
      // setSlabs(response.data);
      // resetFilters();
      getFeeSlabs();
      setFetchSlabsLoading(false);
      toast.success("Contract Payment Fee Deleted Successfully!");
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      // toast.error(error.response.data.message);
      setFetchSlabsLoading(false);
    }
  };

  const getFeeSlabs = useCallback(
    async (filter) => {
      if (filter?.group !== "") {
        setFetchSlabsLoading(true);
        try {
          const response = await merchantApi.get(
            `${API.TRANSACTION_TYPE_FEE}`,
            {
              params: {
                offset: paginateTable.currentPage * paginateTable.pageSize,
                limit: paginateTable.pageSize,
                search: paginateTable.search,
                transaction_type: "ContractPayment",
                ...filter,
              },
            }
          );
          console.log(response);
          setSlabs(response.data);
          setFetchSlabsLoading(false);
        } catch (error) {
          console.log(error);
          toast.error(error?.data?.data?.message || "Something went wrong");
          setFetchSlabsLoading(false);
        }
      }
    },
    [paginateTable]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      getFeeSlabs(filters);
    }, 500);
    return () => clearTimeout(timer);
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    getFeeSlabs,
    filters,
  ]);

  const confirmDelete = () => {
    deleteSlab(deleteId);
    toggleShowDeleteModal();
  };

  const getCustomerGroup = async () => {
    try {
      const response = await merchantApi.get(`${API.CUSTOMER_FEE_GROUP}`, {
        params: {
          limit: 100,
        },
      });
      setCustomerGroup(response.data?.results);
    } catch (error) {
      toast.error("Error Getting Customer Groups");
    }
  };

  function handleClearFilters() {
    setSlabs([]);
    setFilters({
      group: "",
    });
  }

  useEffect(() => {
    getCustomerGroup();
  }, []);

  const onShowHistoryClicked = () => {
    toggleFeeHistoryModal();
    getFeeHistoryTable();
  };

  async function getFeeHistoryTable() {
    setFeesHistoryLoading(true);
    const groupId = customerGroup?.find(
      (item) => item?.name === filters?.group
    );
    try {
      const response = await merchantApi.get(
        `${API.TRANSACTION_TYPE_FEE_HISTORY}ContractPayment/${groupId?.id}/history/`
      );
      setFeesHistoryLoading(false);
      setFeeHistoryData(response.data?.data?.history);
    } catch (error) {
      setFeesHistoryLoading(false);
      toast.error("Error Getting Fee History");
    }
  }

  return (
    <Box sx={{ padding: "0 24px 24px" }}>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        width={"100%"}
        mb={2}
      >
        <ButtonPrimary
          style={{
            maxWidth: "300px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
          onClick={toggleAddNewSlabModal}
        >
          <Iconify icon="material-symbols:add" width={22} /> Add New Contract
          Payment Fee
        </ButtonPrimary>
      </Stack>
      <Stack
        sx={{
          overflowX: { xs: "auto" },
          overflowY: { xs: "visible", md: "unset" },
          padding: "5px 0",
          mb: 1,
        }}
        direction={"row"}
        // justifyContent={"space-between"}
        gap={1}
        mt={2}
        width={"100%"}
      >
        <Stack
          sx={{ flexDirection: "row", gap: 1 }}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderStyle: "dashed" }}
            />
          }
        >
          <Stack sx={{ minWidth: "200px", flex: 1 }}>
            <FilterDropdown
              name="group"
              type="text"
              label="Group"
              options={customerGroup}
              onChange={(e) => {
                setFilters((prev) => ({
                  ...prev,
                  group: e.target.value,
                }));
              }}
              value={filters?.group}
              size={"small"}
              render={(period, i) => (
                <MenuItem key={period.name} value={period.name}>
                  {period.name}
                </MenuItem>
              )}
            />
          </Stack>
          {filters?.group !== "" && (
            <Stack
              sx={{ flex: 1 }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Button
                variant="contained"
                color="secondary"
                type="button"
                size="small"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "14px",
                }}
                onClick={() => {
                  onShowHistoryClicked();
                  // get;
                }}
              >
                <Iconify icon="iconamoon:history-bold" width={20} />
                Fee History Details
              </Button>
            </Stack>
          )}

          {/* <Stack sx={{ minWidth: "200px", flex: 1 }}>
              <Autocomplete
                id="settlement_status"
                value={filters.settlement_status}
                onChange={(e, newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    settlement_status: newValue,
                  }));
                }}
                options={paymentStatus}
                getOptionLabel={(option) => option?.name}
                sx={{ flexGrow: 1, width: "100%" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select Status"
                      {...params}
                      size="small"
                    />
                  );
                }}
              />
            </Stack> */}
        </Stack>
        <Stack sx={{ flexDirection: "row", gap: 1 }}>
          <ButtonResetFilter onClick={handleClearFilters} />
        </Stack>
      </Stack>

      <ContractPaymentFeesTable
        fetchSlabsLoading={fetchSlabsLoading}
        slabs={slabs}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
        setSlabToEdit={setSlabToEdit}
        setIsEdit={setIsEdit}
        toggleAddNewSlabModal={toggleAddNewSlabModal}
        filter={filters}
        deleteSlab={(id) => {
          setDeleteId(id);
          toggleShowDeleteModal();
        }}
      />
      {showAddSlabModal && (
        <AddEditTransactionTypeFeeModal
          onClose={toggleAddNewSlabModal}
          isOpen={showAddSlabModal}
          isEdit={isEdit}
          slabToEdit={slabToEdit}
          setSlabToEdit={setSlabToEdit}
          // This is to trigger re-render after adding user to fetch new list
          onSubmit={() => {
            toggleAddNewSlabModal();
            getFeeSlabs(filters);
          }}
          type={"ContractPayment"}
        />
      )}
      {showFeeHistoryModal && (
        <FeeHistoryModal
          fee={slabToEdit}
          isOpen={showFeeHistoryModal}
          onClose={toggleFeeHistoryModal}
          group={filters?.group || null}
          feeHistoryData={feeHistoryData}
          loading={feesHistoryLoading}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onClose={toggleShowDeleteModal}
          isOpen={showDeleteModal}
          onSubmit={confirmDelete}
          text={"the Contract Payment Fee"}
        />
      )}
    </Box>
  );
};

export default ContractPaymentFees;
