import PropTypes from "prop-types";
import React, { useState, useMemo, useEffect } from "react";
// form
import { useTheme } from "@mui/material/styles";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Card, Stack, Divider, Typography } from "@mui/material";

import PaymentMethods from "./PaymentMethods";
import PaymentMethodDetails from "./PaymentMethodDetails";
import PaymentAccounts from "./PaymentAccounts";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import usePageTitle from "../../../hooks/usePageTitle";
import { merchantApi } from "../../../redux/api/apiConfig";
import { FINANCE_ACCOUNT_DATA, FINANCIAL_OPTIONS } from "../../../ApiUrl";
import { toast } from "react-toastify";
import EditFinanceAccountDetailModal from "../../../components/EditFinanceAccountDetailModal";

// ----------------------------------------------------------------------

FinancialAccounts.propTypes = {
  currentInvoice: PropTypes.object,
};

export default function FinancialAccounts() {
  const [financialAccountOptions, setFinancialAccountOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountDetails, setAccountDetails] = useState(null);
  const [editAccountDetails, setEditAccountDetails] = useState(null);
  const [showEditAccountDetails, setShowEditAccountDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function toggleShowEditAccountDetails() {
    if (showEditAccountDetails) {
      setEditAccountDetails(null);
    }
    setShowEditAccountDetails((prev) => !prev);
  }

  const { user } = useSelector((state) => state.profileReducer);

  const { finance_account } = user;
  console.log(user);

  const getFinancialInstruments = async () => {
    try {
      const response = await merchantApi.get(FINANCIAL_OPTIONS);
      setFinancialAccountOptions(response.data.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      console.log(error);
    }
  };

  async function getFinanceAccountDetails() {
    setIsLoading(true);
    try {
      const response = await merchantApi.get(FINANCE_ACCOUNT_DATA);
      console.log("account details", response);
      setAccountDetails(response?.data);
      setIsLoading(false);
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      setIsLoading(false);
    }
  }

  usePageTitle("Financial Accounts", "View your financial accounts");

  // const initialValues = useMemo(() => {
  //   if (finance_account?.length === 0) {
  //     return {
  //       payment_method: 'bank',
  //       account: finance_account[0]?.id.toString(),
  //     };
  //   } else {
  //     return {
  //       payment_method: finance_account[0]?.type,
  //       account: finance_account[0]?.id.toString(),
  //     };
  //   }
  // }, [finance_account]);

  const initialValues = {
    payment_method: "bank",
    account: 1,
  };

  const formik = useFormik({
    initialValues,
  });

  const { values, setFieldValue } = formik;

  useEffect(() => {
    const accountOptions = finance_account?.filter(
      (method) => method.type == values.payment_method
    );
    setAccountOptions(accountOptions);
    setFieldValue("account", 1);
  }, [values.payment_method, setFieldValue, finance_account]);

  useEffect(() => {
    const accountDetails = finance_account?.find(
      (method) => method.id == values.account
    );
    setAccountDetails(accountDetails);
  }, [values.account, finance_account]);

  useEffect(() => {
    // getFinancialInstruments();
    getFinanceAccountDetails();
  }, []);

  return (
    <>
      <Box sx={{ padding: "0 24px 24px" }}>
        <Card sx={{ p: 3 }}>
          {/* <PaymentMethods options={financialAccountOptions} formik={formik} />
        <Divider variant="middle" sx={{ borderStyle: "dashed", my: 3 }} /> */}
          {/* <PaymentAccounts
          options={accountOptions}
          accountDetails={accountDetails}
          name={"account"}
          formik={formik}
        /> */}
          {/* <Divider variant="middle" sx={{ borderStyle: "dashed", my: 3 }} /> */}
          <PaymentMethodDetails
            info={accountDetails}
            isLoading={isLoading}
            setEditAccountDetails={setEditAccountDetails}
            toggleShowEditAccountDetails={toggleShowEditAccountDetails}
          />
        </Card>
      </Box>
      {showEditAccountDetails && (
        <EditFinanceAccountDetailModal
          onClose={toggleShowEditAccountDetails}
          isOpen={showEditAccountDetails}
          slabToEdit={editAccountDetails}
          setSlabToEdit={setEditAccountDetails}
          // This is to trigger re-render after adding user to fetch new list
          onSubmit={() => {
            toggleShowEditAccountDetails();
            getFinanceAccountDetails();
          }}
        />
      )}
    </>
  );
}
