import React, { useState, useEffect, useCallback } from "react";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { AddNewSlabModal } from "../feesComponents";
import "rsuite/dist/styles/rsuite-default.css";
import { Button } from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import * as API from "../../../../ApiUrl";
import usePageTitle from "../../../../hooks/usePageTitle";
import { Box, Divider, MenuItem, Stack } from "@mui/material";
import {
  ButtonPrimary,
  ButtonResetFilter,
  Iconify,
} from "../../../../components";
import { useSelector } from "react-redux";
import FeeHistoryModal from "../../../../components/feeHistoryModal/FeeHistoryModal";
import AddEditTransactionTypeFeeModal from "../feesComponents/addEditTransactionTypeFeeModal/addEditTransactionTypeFeeModal";
import useDeleteModal from "../../../../hooks/useDeleteModal";
import { DeleteModal } from "../../../../components/deleteModal";
import { FilterDropdown } from "../../../reports/components";
import CustomerFeeGroupTable from "./CustomerFeeGroupTable";
import AddCustomerFeeGroupModal from "../feesComponents/addCustomerFeeGroupModal/addCustomerFeeGroupModal";
import { Routes } from "../../../../routes";
import { useHistory } from "react-router-dom";
import CustomerFeeGroupHistoryModal from "../../../../components/feeHistoryModal/CustomerFeeGroupHistoryModal";

const CustomerFeeGroup = () => {
  const [slabs, setSlabs] = useState([]);
  const [fetchSlabsLoading, setFetchSlabsLoading] = useState(true);
  const [showAddSlabModal, setShowAddSlabModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [slabToEdit, setSlabToEdit] = useState(null);
  const [filters, setFilters] = useState({
    group: "",
  });
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: "",
  });
  const [showFeeHistoryModal, setShowFeeHistoryModal] = useState(false);
  const [customerGroup, setCustomerGroup] = useState(null);
  const [feesHistoryLoading, setFeesHistoryLoading] = useState(false);
  const [feeHistoryData, setFeeHistoryData] = useState([]);
  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();
  const history = useHistory();

  const toggleFeeHistoryModal = () => {
    setShowFeeHistoryModal((prevState) => !prevState);
  };

  usePageTitle("Customer Fees Group", "View Customer Fees Group List");

  useEffect(() => {
    console.log(slabToEdit);
  }, [slabToEdit]);

  const toggleAddNewSlabModal = () => {
    if (showAddSlabModal) {
      getFeeSlabs(filters);
      setIsEdit(false);
      setSlabToEdit(null);
    }
    setShowAddSlabModal((prevState) => !prevState);
  };

  const deleteSlab = async (id) => {
    setFetchSlabsLoading(true);
    try {
      const response = await merchantApi.delete(
        `${API.CUSTOMER_FEE_GROUP}${id}/`
      );
      console.log(response);
      // setSlabs(response.data);
      // resetFilters();
      getFeeSlabs(filters);
      setFetchSlabsLoading(false);
      toast.success("Fee Group Deleted Successfully!");
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      // toast.error(error.response.data.message);
      setFetchSlabsLoading(false);
    }
  };

  const getFeeSlabs = useCallback(
    async (filter) => {
      setFetchSlabsLoading(true);
      try {
        const response = await merchantApi.get(`${API.CUSTOMER_FEE_GROUP}`, {
          params: {
            offset: paginateTable.currentPage * paginateTable.pageSize,
            limit: paginateTable.pageSize,
            search: filter?.group,
          },
        });
        console.log(response);
        setSlabs(response.data);
        setFetchSlabsLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.data?.message || "Something went wrong");
        setFetchSlabsLoading(false);
      }
    },
    [paginateTable]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      getFeeSlabs(filters);
    }, 500);
    return () => clearTimeout(timer);
  }, [
    paginateTable.currentPage,
    paginateTable.pageSize,
    paginateTable.search,
    getFeeSlabs,
    filters,
  ]);

  const confirmDelete = () => {
    deleteSlab(deleteId);
    toggleShowDeleteModal();
  };

  const getCustomerGroup = async () => {
    try {
      const response = await merchantApi.get(`${API.CUSTOMER_FEE_GROUP}`, {
        params: {
          limit: 100,
        },
      });
      setCustomerGroup(response.data?.results);
    } catch (error) {
      toast.error("Error Getting Customer Groups");
    }
  };

  function handleClearFilters() {
    setFilters({
      group: "",
    });
  }

  useEffect(() => {
    getCustomerGroup();
  }, []);

  const viewDetailsHandler = (id) => {
    // console.log("view detail", Routes.CustomerFeeGroupDetail.path + `/${id}`);
    history.push(Routes.CustomerFeeGroup.path + `/${id}`);
  };

  async function getFeeHistoryTable(id) {
    setFeesHistoryLoading(true);
    try {
      const response = await merchantApi.get(
        `${API.CUSTOMER_FEE_GROUP_HISTORY}${id}/history/`
      );
      setFeesHistoryLoading(false);
      setFeeHistoryData(response.data?.data?.history);
    } catch (error) {
      setFeesHistoryLoading(false);
      toast.error("Error Getting Fee History");
    }
  }

  return (
    <Box sx={{ padding: "0 24px 24px" }}>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        width={"100%"}
        mb={2}
      >
        <ButtonPrimary
          style={{
            maxWidth: "300px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
          onClick={toggleAddNewSlabModal}
        >
          <Iconify icon="material-symbols:add" width={22} /> Add New Customer
          Fee Group
        </ButtonPrimary>
      </Stack>
      <Stack
        sx={{
          overflowX: { xs: "auto" },
          overflowY: { xs: "visible", md: "unset" },
          padding: "5px 0",
          mb: 1,
        }}
        direction={"row"}
        // justifyContent={"space-between"}
        gap={1}
        mt={2}
        width={"100%"}
      >
        <Stack
          sx={{ flexDirection: "row", gap: 1 }}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderStyle: "dashed" }}
            />
          }
        >
          <Stack sx={{ minWidth: "280px", flex: 1 }}>
            <FilterDropdown
              name="group"
              type="text"
              label="Select Customer Fees Group"
              options={customerGroup}
              onChange={(e) => {
                setFilters((prev) => ({
                  ...prev,
                  group: e.target.value,
                }));
              }}
              value={filters?.group}
              size={"small"}
              render={(period, i) => (
                <MenuItem key={period.name} value={period.name}>
                  {period.name}
                </MenuItem>
              )}
            />
          </Stack>

          {/* <Stack sx={{ minWidth: "200px", flex: 1 }}>
              <Autocomplete
                id="settlement_status"
                value={filters.settlement_status}
                onChange={(e, newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    settlement_status: newValue,
                  }));
                }}
                options={paymentStatus}
                getOptionLabel={(option) => option?.name}
                sx={{ flexGrow: 1, width: "100%" }}
                renderInput={(params) => {
                  return (
                    <TextField
                      placeholder="Select Status"
                      {...params}
                      size="small"
                    />
                  );
                }}
              />
            </Stack> */}
        </Stack>
        <Stack sx={{ flexDirection: "row", gap: 1 }}>
          <ButtonResetFilter onClick={handleClearFilters} />
        </Stack>
      </Stack>

      <CustomerFeeGroupTable
        fetchSlabsLoading={fetchSlabsLoading}
        slabs={slabs}
        paginateTable={paginateTable}
        setPaginateTable={setPaginateTable}
        setSlabToEdit={setSlabToEdit}
        setIsEdit={setIsEdit}
        viewDetails={viewDetailsHandler}
        toggleAddNewSlabModal={toggleAddNewSlabModal}
        toggleFeeHistoryModal={toggleFeeHistoryModal}
        getFeeHistoryTable={getFeeHistoryTable}
        deleteSlab={(id) => {
          setDeleteId(id);
          toggleShowDeleteModal();
        }}
      />
      {showAddSlabModal && (
        <AddCustomerFeeGroupModal
          onClose={toggleAddNewSlabModal}
          isOpen={showAddSlabModal}
          isEdit={isEdit}
          slabToEdit={slabToEdit}
          setSlabToEdit={setSlabToEdit}
          // This is to trigger re-render after adding user to fetch new list
          onSubmit={() => {
            toggleAddNewSlabModal();
            getFeeSlabs(filters);
            getCustomerGroup();
          }}
        />
      )}
      {showFeeHistoryModal && (
        <CustomerFeeGroupHistoryModal
          fee={slabToEdit}
          isOpen={showFeeHistoryModal}
          onClose={() => {
            toggleFeeHistoryModal();
            setFeeHistoryData([]);
          }}
          group={filters?.group || null}
          feeHistoryData={feeHistoryData}
          loading={feesHistoryLoading}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          onClose={toggleShowDeleteModal}
          isOpen={showDeleteModal}
          onSubmit={confirmDelete}
          text={"the Customer Fee Group"}
        />
      )}
    </Box>
  );
};

export default CustomerFeeGroup;
