import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Stack, Typography, LinearProgress } from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
} from "../../../utils/common";
import { DataGrid } from "@mui/x-data-grid";
import { tableCellStyle } from "../../../utils/common";
import { Label } from "../../../components";
import { fCurrency } from "../../../utils/formatNumber";
import TableWrapper from "../../../components/TableWrapper";

export default function MonthlyPaymentFeeReportTable({
  translist,
  fetchTranslistLoading,
  filters,
  setFilters,
  client,
}) {
  const history = useHistory();

  const handleChangePage = (newPage) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: newPage,
    }));
  };

  const handleChangeRowsPerPage = (newSize) => {
    setFilters((prev) => ({
      ...prev,
      currentPage: 0,
      pageSize: newSize,
    }));
  };

  const createDataRow = (transaction) => {
    // const type = transaction.type;
    return {
      id: transaction?.id,
      date: formatWithoutTimezone(transaction?.created_date, "dd MMM yyyy"),
      type: transaction?.type,
      debtor:
        transaction?.debtor?.business?.name ||
        transaction?.debtor?.name ||
        "--",
      creditor:
        transaction?.creditor?.business?.name ||
        transaction?.creditor?.name ||
        "--",
      amount:
        transaction?.debtor?.name === "Master Account"
          ? {
              isDebit: true,
              amount: fCurrency(transaction?.amount),
            }
          : transaction?.creditor?.name === "Master Account"
          ? {
              isDebit: false,
              amount: fCurrency(transaction?.amount),
            }
          : {
              isDebit: null,
              amount: fCurrency(transaction?.amount),
            },
      balance_post_txn: fCurrency(transaction?.debtor_balance_after_txn),
      status: transaction?.status,
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "Client ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "txn_number",
      headerName: "Txn Number",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "txn_type",
      headerName: "Txn Type",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "txn_time",
      headerName: "Txn Time",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Stack
            sx={{ width: "100%", height: "100%", cursor: "pointer" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant="body2"
              style={{ ...tableCellStyle, fontWeight: 600 }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "payer_bank_name",
      headerName: "Payer Bank Name",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "payer_bank_bic",
      headerName: "Payer Bank BIC",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            {/* <Avatar
              alt={params.formattedValue}
              sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
            >
              {createAvatar(params.formattedValue).name}
            </Avatar> */}
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue?.display}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "payer_account_number",
      headerName: "Payer Account Number",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                  color: params?.formattedValue?.isDebit ? "red" : "green",
                }}
              >
                {params?.formattedValue?.amount
                  ? params?.formattedValue?.isDebit !== null
                    ? `${params?.formattedValue?.isDebit ? "-" : "+"} ${
                        params?.formattedValue?.amount
                      }`
                    : params?.formattedValue?.amount
                  : "--"}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "payer_account_name",
      headerName: "Payer Account Name",
      sortable: false,
      flex: 1.5,
      minWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
            <Typography
              variant="body2"
              sx={{
                color: "black",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "500",
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "payer_name",
      headerName: "Payer Name",
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: "ellipsis",
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Stack direction={"row"} sx={{ minWidth: 0, alignItems: "center" }}>
            {/* <Avatar
                alt={params.formattedValue}
                sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}
              >
                {createAvatar(params.formattedValue).name}
              </Avatar> */}
            <Stack>
              <Typography
                variant="body2"
                sx={{
                  color: "black",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: "500",
                }}
              >
                {params?.formattedValue}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "beneficiary_bank_name",
      headerName: "Beneficiary Bank Name",
      flex: 1,
      minWidth: 250,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {`${params.formattedValue}`}
          </Typography>
        );
      },
    },
    {
      field: "bic",
      headerName: "BIC",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "account_number",
      headerName: "Account Number",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "account_name",
      headerName: "Account Name",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "beneficiary_name",
      headerName: "Beneficiary Name",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "fee_amount",
      headerName: "Total Fee Amount",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "stb_fee_amount",
      headerName: "STB/Buckzy Fee Amount",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "purpose",
      headerName: "Purpose",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "remark",
      headerName: "Remark",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker" ||
                  params.formattedValue?.value === "RejectedByBuckzy"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
  ];

  return (
    <>
      <TableWrapper>
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          // onRowClick={(row) => {
          //   history.push({
          //     pathname: Routes.ProgrammedLiensDetailView.path,
          //     search: `?id=${row.id}`,
          //   });
          // }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters?.currentPage}
          rowCount={translist?.count}
          pageSize={filters?.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </TableWrapper>
    </>
  );
}
