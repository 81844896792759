import React, { useState, useEffect, useCallback } from "react";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { AddNewSlabModal } from "../feesComponents";
import "rsuite/dist/styles/rsuite-default.css";
import { Button } from "@themesberg/react-bootstrap";
import { toast } from "react-toastify";
import * as API from "../../../../ApiUrl";
import usePageTitle from "../../../../hooks/usePageTitle";
import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ButtonPrimary,
  ButtonResetFilter,
  Iconify,
} from "../../../../components";
import { useSelector } from "react-redux";
import FeeHistoryModal from "../../../../components/feeHistoryModal/FeeHistoryModal";
import AddEditTransactionTypeFeeModal from "../feesComponents/addEditTransactionTypeFeeModal/addEditTransactionTypeFeeModal";
import useDeleteModal from "../../../../hooks/useDeleteModal";
import { DeleteModal } from "../../../../components/deleteModal";
import { FilterDropdown } from "../../../reports/components";
import CustomerFeeGroupTable from "./CustomerFeeGroupTable";
import AddCustomerFeeGroupModal from "../feesComponents/addCustomerFeeGroupModal/addCustomerFeeGroupModal";
import { useHistory, useParams } from "react-router-dom";
import { formatWithoutTimezone } from "../../../../utils/common";
import CustomerList from "./CustomerList";
import AddCustomerList from "./AddCustomerList";
import back from "../../../../assets/img/icons/back.svg";

const FeeGroupCustomerList = () => {
  const [slabs, setSlabs] = useState([]);
  const [fetchSlabsLoading, setFetchSlabsLoading] = useState(true);
  const [showAddSlabModal, setShowAddSlabModal] = useState(false);
  const [deleteCustomers, setDeleteCustomers] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [slabToEdit, setSlabToEdit] = useState(null);
  const [filters, setFilters] = useState({
    group: "",
  });
  const [paginateTable, setPaginateTable] = useState({
    currentPage: 0,
    pageSize: 7,
    search: "",
  });
  const [showFeeHistoryModal, setShowFeeHistoryModal] = useState(false);
  const [customerGroup, setCustomerGroup] = useState(null);
  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const theme = useTheme();

  usePageTitle("Fee Group Customer Details", "View Customer Detail");

  useEffect(() => {
    console.log(slabToEdit);
  }, [slabToEdit]);

  const deleteSlab = async (data) => {
    console.log("delte customer data", data);
    setFetchSlabsLoading(true);
    try {
      const response = await merchantApi.post(
        `${API.REMOVE_CUSTOMER_FEE_GROUP}`,
        {
          customer_ids: data,
          fee_group_id: id,
        }
      );
      console.log(response);
      // setSlabs(response.data);
      // resetFilters();
      getFeeSlabs();
      setDeleteCustomers([]);
      setFetchSlabsLoading(false);
      toast.success("Fee Group Deleted Successfully!");
    } catch (error) {
      toast.error(error?.data?.data?.message || "Something went wrong");
      // toast.error(error.response.data.message);
      setFetchSlabsLoading(false);
    }
  };

  const getFeeSlabs = useCallback(async () => {
    setFetchSlabsLoading(true);
    try {
      const response = await merchantApi.get(`${API.CUSTOMER_FEE_GROUP}${id}/`);
      console.log(response);
      setSlabs(response.data);
      setFetchSlabsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.data?.data?.message || "Something went wrong");
      setFetchSlabsLoading(false);
    }
  }, [paginateTable]);

  useEffect(() => {
    getFeeSlabs();
  }, []);

  const confirmDelete = () => {
    deleteSlab(deleteCustomers);
    toggleShowDeleteModal();
  };

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <Box sx={{ padding: "0 24px 24px" }}>
      <Box>
        <span onClick={onBackClick} className="back mb-4">
          <img src={back} alt="back" />
          <span>Back</span>
        </span>
      </Box>
      <Grid container sx={{ my: 3 }}>
        <Grid item md={8}>
          <Box
            sx={{
              p: 3,
              borderRadius: "7px",
              backgroundColor: theme.palette.common.accentColor,
            }}
            direction={"column"}
            gap={2}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                fontWeight: "600",
                fontSize: "20px",
                mb: 1,
              }}
            >
              Group Details
            </Typography>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderStyle: "solid", borderColor: "#fff" }}
            />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 2,
                mt: 2,
              }}
            >
              <Typography variant="p" sx={{ color: "#fff", fontSize: "16px" }}>
                <b>Name:</b>
                <br />
                {slabs?.name}
              </Typography>
              <Typography variant="p" sx={{ color: "#fff", fontSize: "16px" }}>
                <b>Created Date:</b>
                <br />
                {slabs?.created_date
                  ? formatWithoutTimezone(slabs?.created_date, "dd MMM yyyy")
                  : "--"}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ my: 3 }} spacing={2}>
        <Grid item lg={6}>
          <Box
            sx={{
              p: 3,
              borderRadius: "7px",
              backgroundColor: theme.palette.common.lightAccentColor,
            }}
            direction={"column"}
            gap={2}
          >
            <Typography
              variant="h6"
              sx={{
                // color: "#fff",
                fontWeight: "600",
                fontSize: "20px",
                mb: 1,
              }}
            >
              Group Customer List
            </Typography>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                borderStyle: "solid",
                borderColor: theme.palette.common.accentColor,
                mb: 2,
              }}
            />
            <CustomerList
              customersData={slabs?.customers}
              loading={fetchSlabsLoading}
              handleRemove={(data) => {
                toggleShowDeleteModal();
                setDeleteCustomers(data);
              }}
            />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box
            sx={{
              p: 3,
              borderRadius: "7px",
              backgroundColor: theme.palette.common.lightAccentColor,
            }}
            direction={"column"}
            gap={2}
          >
            <Typography
              variant="h6"
              sx={{
                // color: "#fff",
                fontWeight: "600",
                fontSize: "20px",
                mb: 1,
              }}
            >
              Add Customers
            </Typography>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                borderStyle: "solid",
                borderColor: theme.palette.common.accentColor,
                mb: 2,
              }}
            />
            <AddCustomerList feeId={id} getFeeSlabs={getFeeSlabs} />
          </Box>
        </Grid>
      </Grid>

      {showDeleteModal && (
        <DeleteModal
          onClose={toggleShowDeleteModal}
          isOpen={showDeleteModal}
          onSubmit={confirmDelete}
          text={"the customers from this Fee Group"}
        />
      )}
    </Box>
  );
};

export default FeeGroupCustomerList;
