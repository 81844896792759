import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent, TextFieldSelect } from "../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../redux/api/apiConfig";
import {
  FEE,
  INTERNAL_TRANSFER,
  SUB_ACCOUNT_LIST,
  SUPPLIER_BUS,
} from "../../ApiUrl";
import SearchNotFound from "../SearchNotFound";
import { accountTypes, handleApiErrors } from "../../utils/common";
import SearchableSelectField from "../searchableSelectField";

const validationSchema = yup.object({
  creditor: yup.mixed().required("Creditor is required."),
  debtor: yup.mixed().required("Debtor is required."),
  // description: yup.string().required("Description is required"),
  amount: yup
    .number("Enter amount")
    .min(0, "Please enter valid amount")
    .required("Amount is required!"),
});

const InternalTransferModal = ({ onClose, isOpen, onSubmit }) => {
  const [loading, setLoading] = useState(false);

  const fetchOptions = async (query) => {
    const url = query
      ? `${SUB_ACCOUNT_LIST}?search=${query}`
      : `${SUB_ACCOUNT_LIST}`; // No search parameter when query is empty

    const response = await merchantApi.get(url);
    return response?.data.map((item) => ({
      label: item?.user_display, // Adjust based on your API response
      value: item?.id,
    }));
  };

  const addClickHandler = async (values) => {
    console.log("internal transfer values", values);

    try {
      setLoading(true);
      const response = await merchantApi.post(INTERNAL_TRANSFER, {
        ...values,
        creditor: values?.creditor?.value,
        debtor: values?.debtor?.value,
      });
      setLoading(false);
      toast.success("Internal Transfer Created Successfully!");
      onSubmit();
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      debtor: null,
      creditor: null,
      amount: "",
      description: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: addClickHandler,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"xs"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
            // width: '628px',
            // minHeight: '550px',
            // maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Create Internal Transfer
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
          >
            <Stack
              spacing={1}
              direction={"column"}
              alignItems={"center"}
              mb={1}
            >
              <SearchableSelectField
                label="From"
                name="debtor"
                placeholder="Type to search"
                formik={formik}
                fetchOptions={fetchOptions}
                required
              />
              <SearchableSelectField
                label="To"
                name="creditor"
                placeholder="Type to search"
                formik={formik}
                fetchOptions={fetchOptions}
                required
              />
              <TextFieldComponent
                formik={formik}
                name={"amount"}
                type={"number"}
                label={"Amount"}
                required
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{"USD"}</InputAdornment>
                  ),
                }}
              />
              <TextFieldComponent
                formik={formik}
                name={"description"}
                type={"text"}
                label={"Description*"}
                multiline
                rows={4}
              />
            </Stack>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={onClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                Create
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default InternalTransferModal;
