import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

const SearchableSelectField = ({
  label,
  placeholder,
  name,
  size = "large",
  formik,
  required,
  fetchOptions, // Function to fetch options from API
  onChange,
  ...others
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to fetch data from API
  const loadOptions = async (query) => {
    setLoading(true);
    const response = await fetchOptions(query);
    setOptions(response);
    setLoading(false);
  };

  // Fetch customers when the input value changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue === "") {
        loadOptions("");
      } else {
        loadOptions(inputValue); // Clear options if input is empty
      }
    }, 300); // Add debounce delay to avoid too many API calls

    return () => clearTimeout(delayDebounceFn); // Cleanup timeout
  }, [inputValue]);

  return (
    <Stack spacing={0} width={"100%"} gap={1}>
      <Typography>
        {label} {required ? "*" : ""}
      </Typography>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label}
        filterOptions={(x) => x}
        value={formik.values[name]}
        loading={loading}
        onInputChange={(event, value) => {
          return setInputValue(value);
        }}
        onChange={(event, value) => {
          formik.setFieldValue(name, value || null);
          if (onChange) onChange(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            // label={`${label} ${required ? "*" : ""}`}
            placeholder={placeholder}
            variant="outlined"
            size={size}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        {...others}
      />
    </Stack>
  );
};

export default SearchableSelectField;
