import React, { useMemo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import {
  formatCurrency,
  formatWithoutTimezone,
  rowsPerPageOptions,
  tableCellStyle,
  tableStyle,
} from "../../../../utils/common";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Tooltip,
  useTheme,
  Button,
} from "@mui/material";
import useDeleteModal from "../../../../hooks/useDeleteModal";
import { DeleteModal } from "../../../../components/deleteModal";
import HistoryIcon from "@mui/icons-material/History";
import { fCurrency } from "../../../../utils/formatNumber";
import { Iconify } from "../../../../components";
import TableWrapper from "../../../../components/TableWrapper";

const CustomerFeeGroupTable = ({
  slabs,
  paginateTable,
  setPaginateTable,
  fetchSlabsLoading,
  setSlabToEdit,
  setIsEdit,
  toggleAddNewSlabModal,
  deleteSlab,
  toggleFeeHistoryModal,
  viewDetails,
  getFeeHistoryTable,
}) => {
  const theme = useTheme();

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {paginateTable.search === "" ? "No Slabs Added" : "No Slabs Found"}
      </Stack>
    );
  };

  const createDataRow = (slab) => {
    return {
      group: slab?.name || "--",
      id: slab.id,
      customer_list: null,
      actions: slab,
      fee_history: slab,
      created_date: slab?.created_date
        ? formatWithoutTimezone(slab?.created_date, "dd MMM yyyy HH:mm a")
        : "--",
    };
  };

  const handleCellClick = (e, params, type) => {
    e.stopPropagation(); // Prevent the row click event
    if (type === "edit") {
      return onEditClicked(params.formattedValue);
    } else if (type === "history") {
      return onShowHistoryClicked(params.formattedValue);
    } else {
      return deleteSlab(params.formattedValue?.id);
    }
  };

  const rows = useMemo(
    () =>
      slabs?.results?.length > 0
        ? slabs.results.map((slab) => createDataRow(slab))
        : [],
    [slabs.results]
  );

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        // flex: 1,
        // minWidth: 120,
        // maxWidth: 200,
        headerClassName: "header",
        align: "center",
        headerAlign: "center",
        renderCell: (params) => {
          return (
            <Stack
              // sx={{ width: "100%", height: "100%", cursor: "pointer" }}
              justifyContent={"flex-start"}
              // alignItems={"center"}
            >
              <Typography
                variant="body2"
                style={{ ...tableCellStyle, fontWeight: 600 }}
              >
                {params.formattedValue}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "group",
        headerName: "Group",
        sortable: false,
        flex: 1,
        minWidth: 250,
        // maxWidth: 250,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
        // headerAlign: 'center',
      },
      {
        field: "created_date",
        headerName: "Created Date",
        sortable: false,
        flex: 1,
        minWidth: 200,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
      {
        field: "customer_list",
        headerName: "Customers List",
        sortable: false,
        flex: 1,
        minWidth: 200,
        // maxWidth: 250,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Stack direction={"row"}>
              <Button
                variant="contained" // Choose between "contained", "outlined", or "text"
                //   color="secondary"
                type="button"
                size="small"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "14px",
                }}
              >
                <Iconify icon="clarity:employee-group-line" width={20} />
                Customer Details
              </Button>
            </Stack>
          );
        },
        // align: 'center',
        // headerAlign: 'center',
      },
      {
        field: "fee_history",
        headerName: "Group History",
        sortable: false,
        // flex: 1,
        minWidth: 200,
        // maxWidth: 140,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Box>
              <Button
                variant="contained"
                color="secondary"
                type="button"
                size="small"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  fontSize: "14px",
                }}
                onClick={(e) => {
                  handleCellClick(e, params, "history");
                }}
              >
                <Iconify icon="iconamoon:history-bold" width={20} />
                Fee History Details
              </Button>
            </Box>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        // flex: 1,
        // minWidth: 200,
        // maxWidth: 140,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)" }}>
              <Tooltip title="Edit" arrow>
                <IconButton
                  // disabled={!permission?.change[role]}
                  onClick={(e) => {
                    handleCellClick(e, params, "edit");
                  }}
                >
                  <Iconify
                    icon="raphael:edit"
                    width={22}
                    sx={{ color: theme.palette.common.accentColor }}
                  />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Delete Fee" arrow>
                <IconButton
                  // disabled={!permission?.change[role]}
                  onClick={(e) => {
                    handleCellClick(e, params, "delete");
                  }}
                >
                  <Iconify
                    icon="material-symbols:delete"
                    width={22}
                    sx={{ color: "red" }}
                  />
                </IconButton>
              </Tooltip> */}
            </Box>
          );
        },
      },
    ],
    []
  );

  const onEditClicked = (slab) => {
    setIsEdit(true);
    setSlabToEdit(slab);
    toggleAddNewSlabModal();
  };
  const onShowHistoryClicked = (fee) => {
    getFeeHistoryTable(fee?.id);
    toggleFeeHistoryModal();
  };
  const handleChangePage = useCallback((newPage) => {
    setPaginateTable({ ...paginateTable, currentPage: newPage });
  }, []);

  const handleChangeRowsPerPage = useCallback((newSize) => {
    setPaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  }, []);

  return (
    <div>
      <TableWrapper>
        <DataGrid
          autoHeight
          sx={tableStyle}
          density={"compact"}
          onRowClick={(row) => {
            const id = row?.row?.id;
            viewDetails(id);
          }}
          rows={rows}
          columns={columns}
          rowHeight={72}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode="server"
          rowsPerPageOptions={rowsPerPageOptions}
          loading={fetchSlabsLoading}
          pageSize={paginateTable.pageSize}
          page={paginateTable.currentPage}
          rowCount={slabs.count}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
        />
      </TableWrapper>
    </div>
  );
};

export default CustomerFeeGroupTable;
