import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import "rsuite/dist/styles/rsuite-default.css";

import * as API from "../../../ApiUrl";
import { RemittanceTable } from "./components";
import { merchantApi } from "../../../redux/api/apiConfig";
import { format } from "date-fns";
import { Box, Stack } from "@mui/material";
import usePageTitle from "../../../hooks/usePageTitle";
import { ButtonPrimary, Iconify } from "../../../components";
import { InternalTransferModal } from "../../../components/internalTransferModal";

function InternalTransfers() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [filters, setFilters] = useState({
    currentPage: 0,
    pageSize: 7,
  });

  usePageTitle("Internal Transfers", "View Internal Transfers");

  async function getTransactionData(filters) {
    let params = {
      offset: filters?.currentPage * filters?.pageSize,
      limit: filters?.pageSize,
    };
    setFetchTranslistLoading(true);
    merchantApi
      .get(`${API.INTERNAL_TRANSFER}`, {
        params,
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || "Something went wrong");
        setFetchTranslistLoading(false);
      });
  }

  useEffect(() => {
    getTransactionData(filters);
  }, [filters]);

  const toggleTransferModal = () => {
    setShowTransferModal((state) => !state);
  };

  return (
    <>
      <Box sx={{ padding: "0 24px 24px" }}>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          width={"100%"}
          mb={2}
        >
          <ButtonPrimary
            onClick={toggleTransferModal}
            style={{
              maxWidth: "300px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Iconify icon="fa6-solid:money-bill-transfer" width={22} /> New Internal
            Transfer
          </ButtonPrimary>
        </Stack>
        <RemittanceTable
          translist={translist}
          filters={filters}
          setFilters={setFilters}
          fetchTranslistLoading={fetchTranslistLoading}
        />
        {showTransferModal && (
          <InternalTransferModal
            isOpen={showTransferModal}
            onClose={toggleTransferModal}
            onSubmit={() => {
              getTransactionData(filters);
              toggleTransferModal();
            }}
          />
        )}
      </Box>
    </>
  );
}

export default InternalTransfers;
