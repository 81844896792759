import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { CUSTOMER_TRANSACTION, INCOMING_TRANSFER } from "../../../../ApiUrl";
import { toast } from "react-toastify";
import PaymentClaimsTable from "./paymentClaimsTable";
import PaymentClaimDetail from "./paymentClaimDetail";
import ContractFilesModal from "../../../../components/contractFilesModal/ContractFilesModal";
import ClaimDocumentFileModal from "../../../../components/claimDocumentFileModal/claimDocumentFileModal";

const selectedContractFiles = [
  {
    attachment: 68,
    attachment_url:
      "https://ipusa-dev-media.spendthebits.com/private-content/Screenshot_2024-01-20_123944.png",
    document_name: "Contract Document",
    id: 32,
  },
  {
    attachment: 68,
    attachment_url:
      "https://ipusa-dev-media.spendthebits.com/private-content/Screenshot_2024-01-20_123944.png",
    document_name: "Contract Document",
    id: 33,
  },
];

const PaymentClaims = ({ details, getTransferDetail, setCurrentTab }) => {
  const [showClaimDetail, setShowClaimDetail] = useState(false);
  const [showClaimDocument, setShowClaimDocument] = useState(false);
  const [transactionData, setTransactionData] = useState({
    count: 0,
    results: [],
  });
  const [claimDocuments, setClaimDocuments] = useState(null);
  const [claimDetails, setClaimDetails] = useState(null);
  // const [transactionData, setTransactionData] = useState({
  //   count: 0,
  //   results: [
  //     {
  //       id: 1,
  //       created_date: "2024-09-09",
  //       supplier: {
  //         name: "Supplier A",
  //         email: "suppliera@gmail.com",
  //       },
  //       claim: [],
  //       status: {
  //         display: "Approved",
  //         value: "approved",
  //       },
  //     },
  //     {
  //       id: 2,
  //       created_date: "2024-09-09",
  //       supplier: {
  //         name: "Supplier B",
  //         email: "supplierb@gmail.com",
  //       },
  //       claim: [],
  //       status: {
  //         display: "Rejected",
  //         value: "rejected",
  //       },
  //     },
  //   ],
  // });

  const [loading, seLoading] = useState(false);
  const [filters, setFilters] = useState({
    currentPage: 0,
    pageSize: 7,
  });

  async function getClaimsData() {
    // let params = {
    //   offset: filters?.currentPage * filters?.pageSize,
    //   limit: filters?.pageSize,
    // };
    seLoading(true);
    merchantApi
      .get(`${INCOMING_TRANSFER}${details?.id}/claims/`)
      .then((response) => {
        if (response?.data) {
          seLoading(false);
          console.log(response?.data);
          setTransactionData({
            count: response?.data?.length,
            results: response?.data,
          });
        }
      })
      .catch((error) => {
        seLoading(false);
        toast.error(
          error?.response?.data?.message || "Error getting transaction list"
        );
      });
  }

  useEffect(() => {
    getClaimsData(filters);
  }, [filters]);

  console.log("Transaction data", transactionData);
  return (
    <Box sx={{ mt: 2 }}>
      {!showClaimDetail ? (
        <PaymentClaimsTable
          translist={transactionData}
          filters={filters}
          setFilters={setFilters}
          fetchTranslistLoading={loading}
          setShowClaimDetail={setShowClaimDetail}
          setShowClaimDocument={setShowClaimDocument}
          setClaimDocuments={setClaimDocuments}
          setClaimDetails={setClaimDetails}
          getClaimsData={getClaimsData}
        />
      ) : (
        <PaymentClaimDetail
          setShowClaimDetail={setShowClaimDetail}
          claimDetails={claimDetails}
          getClaimsData={getClaimsData}
          getTransferDetail={getTransferDetail}
          setCurrentTab={setCurrentTab}
        />
      )}
      {showClaimDocument && (
        <ClaimDocumentFileModal
          isOpen={showClaimDocument}
          onClose={() => setShowClaimDocument(false)}
          selectedContractFiles={claimDocuments}
        />
      )}
    </Box>
  );
};

export default PaymentClaims;
