import { Box } from "@mui/material";
import React from "react";

const TableWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
        borderRadius: "8px",
        // height: 825,
        width: "100%",
        "& .MuiDataGrid-virtualScrollerRenderZone": {
          "& .MuiDataGrid-row": {
            // backgroundColor: 'rgba(235, 235, 235, .7)',
            fontWeight: 500,
            color: "#667085",
            fontFamily: "Inter, sans-serif",
            fontSize: "14px",
          },
        },
        "& .header": {
          backgroundColor: "#F4F4F4",
          "& div": {
            "& div": {
              "& div": {
                "& div": {
                  fontSize: "14px",
                  fontWeight: 600,
                  fontFamily: "Inter, sans-serif",
                },
              },
            },
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

export default TableWrapper;
