import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import { Button } from "rsuite";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextFieldComponent, TextFieldSelect } from "../../../../components";

const StatusModal = ({
  isOpen,
  onClose,
  updateClickHandler,
  statusUpdateLoading,
  status,
}) => {
  const yupObject = {
    status: yup.string("Select a status").required("Status is required."),
  };

  const validationSchema = yup.object(yupObject);

  const formik = useFormik({
    initialValues: {
      status: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    // validateOnChange: true,
  });

  useState(() => {
    formik.setFieldValue("status", status);
  }, [status]);

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"sm"}
      onClose={onClose}
      scroll={"body"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        Update Customer Status
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "0 !important" }}>
        <Box
          sx={{
            display: "grid",
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: { xs: "repeat(1, 1fr)" },
          }}
        >
          <TextFieldSelect
            formik={formik}
            name="status"
            type="text"
            label="Status"
            options={[
              {
                name: "Active",
                value: "active",
              },
              {
                name: "Draft",
                value: "draft",
              },
            ]}
            render={(item) => (
              <MenuItem value={item.value}>{item.name}</MenuItem>
            )}
          />
        </Box>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button
            className="btn white-btn action-button "
            onClick={onClose}
            appearance="subtle"
          >
            Cancel
          </Button>
          <Button
            onClick={() => updateClickHandler(formik.values.status)}
            loading={statusUpdateLoading}
            disabled={!formik.isValid}
            className={`btn action-button primary_color text-white`}
            appearance="primary"
          >
            Update
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default StatusModal;
