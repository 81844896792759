import React, { useEffect, useState } from "react";
import { Box, InputAdornment, Typography } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import TextFieldComponent from "./TextFieldComponent";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../redux/api/apiConfig";
import {
  CUSTOMER_FEE_GROUP,
  FEE,
  FINANCE_ACCOUNT_DATA,
  MONTHLY_FEE,
  TRANSACTION_TYPE_FEE,
} from "../ApiUrl";
// import SearchableSelectField from "../components/searchableSelectField";
import { handleApiErrors } from "../utils/common";

const validationSchema = yup.object({
  account_no: yup
    .string("Enter account number")
    .min(2, "Please enter valid account number")
    .required("Account Number is required!"),
  swift_code: yup.string().required("SWIFT Code is required"),
  aba_number: yup.string("Enter ABA routing number"),
  name: yup
    .string("Enter name")
    .min(2, "Please enter valid name")
    .required("Name is required!"),
  branch: yup.string().required("Branch is required"),
  bank_address: yup
    .string("Enter address")
    .required("Bank Address is required!"),
  address: yup.string("Enter address").required("Address is required!"),
});

const EditFinanceAccountDetailModal = ({
  onClose,
  isOpen,
  onSubmit,
  slabToEdit,
}) => {
  const [loading, setLoading] = useState(false);

  const addClickHandler = async (values) => {
    try {
      setLoading(true);
      const response = await merchantApi.patch(FINANCE_ACCOUNT_DATA, values);
      console.log(response);
      onSubmit();
      toast.success("Account Details Updated Successfully!");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      aba_number: "",
      account_no: "",
      address: "",
      bank_address: "",
      branch: "",
      name: "",
      swift_code: "",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      return addClickHandler(values);
    },
  });

  useEffect(() => {
    if (slabToEdit) {
      formik.setFieldValue("aba_number", slabToEdit?.aba_number);
      formik.setFieldValue("account_no", slabToEdit?.account_no);
      formik.setFieldValue("address", slabToEdit?.address);
      formik.setFieldValue("bank_address", slabToEdit?.bank_address);
      formik.setFieldValue("branch", slabToEdit?.branch);
      formik.setFieldValue("name", slabToEdit?.name);
      formik.setFieldValue("swift_code", slabToEdit?.swift_code);
    }
  }, [slabToEdit]);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"sm"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          Edit Bank Account Details
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
          >
            <Stack
              spacing={2}
              direction={"column"}
              alignItems={"center"}
              mb={1}
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 1,
                }}
              >
                <TextFieldComponent
                  formik={formik}
                  name={"name"}
                  type={"text"}
                  label={"Account Holder Name"}
                  required
                />
                <TextFieldComponent
                  formik={formik}
                  name={"account_no"}
                  type={"text"}
                  label={"Beneficiary's Account Number"}
                  required
                />
                <TextFieldComponent
                  formik={formik}
                  name={"branch"}
                  type={"text"}
                  label={"Beneficiary's Bank"}
                  required
                />
                <TextFieldComponent
                  formik={formik}
                  name={"swift_code"}
                  type={"text"}
                  label={"SWIFT Code"}
                  required
                />
                <TextFieldComponent
                  formik={formik}
                  name={"address"}
                  type={"text"}
                  label={"Beneficiary's Address"}
                  required
                  multiline
                  row={4}
                />
                <TextFieldComponent
                  formik={formik}
                  name={"bank_address"}
                  type={"text"}
                  label={"Beneficiary's Bank Address"}
                  required
                  multiline
                  row={4}
                />
                <TextFieldComponent
                  formik={formik}
                  name={"aba_number"}
                  type={"text"}
                  label={"ABA/Routing Number"}
                  required
                />
              </Box>
            </Stack>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={onClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                Update Account Details
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default EditFinanceAccountDetailModal;
