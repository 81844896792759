import React, { useCallback, useState } from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import { Stack, DialogActions, DialogContent } from "@mui/material";
import { TextFieldComponent, TextFieldSelect } from "../../components";
import { useSelector } from "react-redux";
import SearchNotFound from "../SearchNotFound";
import { CONTRACT_PAYMENT, CONTRACTS, INCOMING_TRANSFER } from "../../ApiUrl";
import { merchantApi } from "../../redux/api/apiConfig";
import { toast } from "react-toastify";
import {
  handleApiErrors,
  searchableSelectFetchOptions,
} from "../../utils/common";
import SearchableSelectField from "../searchableSelectField";
import { fCurrency } from "../../utils/formatNumber";

const validationSchema = yup.object({
  contract: yup.object().required("Contract is required").nullable(),
  amount: yup
    .number("Enter amount")
    .min(0, "Please enter valid amount")
    .required("Amount is required!"),
});

const SettleContractModal = ({
  onClose,
  lenderList,
  onSuccess,
  contractId,
}) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const addClickHandler = async (values) => {
    const requestedData = {
      paid_against_contract: values?.contract?.value,
      lender: values?.customer?.id,
      amount: values?.amount,
      related_incoming_transfer: contractId,
    };

    try {
      setLoading(true);
      const response = await merchantApi.post(CONTRACT_PAYMENT, requestedData);
      toast.success("Contract Payment Success");
      setTimeout(() => {
        formik.resetForm();
        onSuccess();
        onClose();
      }, 1000);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors
      const errorMessages = handleApiErrors(error);
      // Display each error in a separate snackbar
      errorMessages.forEach(({ field, message }) => {
        const formattedMessage = field ? `${field}: ${message}` : message; // Include field name if available
        toast.error(formattedMessage);
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      contract: null,
      amount: "",
      customer: null,
    },
    enableReinitialize: "true",
    validationSchema: validationSchema,
    onSubmit: addClickHandler,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={1} direction={"column"} alignItems={"center"} mb={1}>
        <SearchableSelectField
          label="Contract"
          name="contract"
          placeholder="Type to search"
          formik={formik}
          // fetchOptions={fetchOptions}
          fetchOptions={(query) =>
            searchableSelectFetchOptions(CONTRACTS, query, null, (val) =>
              val?.data?.results?.map((item) => ({
                label: `${item?.customer?.name} owed ${fCurrency(
                  item?.amount
                )} USD ${item?.lender ? ` to ${item?.lender?.name}` : ``}`,
                value: item?.id,
                lender: item?.lender,
              }))
            )
          }
          onChange={(value) => {
            if (value) {
              formik.setFieldValue("contract", value);
              formik.setFieldValue(
                "customer",
                value?.lender
                  ? {
                      id: value?.lender?.id,
                      business_name: value?.lender?.name,
                    }
                  : null
              );
            }
          }}
          required
        />

        <TextFieldComponent
          formik={formik}
          name={"amount"}
          type={"number"}
          label={"Amount*"}
          inputProps={{
            endAdornment: <InputAdornment position="end">USD</InputAdornment>,
          }}
        />

        <Autocomplete
          disablePortal
          sx={{ flexGrow: 1, width: "100%" }}
          id="search"
          getOptionLabel={(option) => {
            return option?.business_name;
          }}
          value={formik.values.customer}
          isOptionEqualToValue={(option, value) => {
            return option?.id === value?.id;
          }}
          options={lenderList}
          onInputChange={(e, value) => setInputValue(value)}
          onChange={(e, value) => {
            if (value) {
              formik.setFieldValue("customer", value);
            }
          }}
          noOptionsText={<SearchNotFound searchQuery={inputValue} />}
          renderInput={(params) => {
            return (
              <Stack
                direction={"column"}
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexGrow: 1,
                }}
                gap={1}
              >
                <Typography>Customer</Typography>
                <TextField
                  {...params}
                  variant="outlined"
                  name={"customer"}
                  // placeholder={`Search Sub Account`}
                  type="text"
                  onBlur={formik?.handleBlur}
                  error={
                    formik?.touched?.customer &&
                    Boolean(formik?.errors?.customer)
                  }
                  helperText={
                    formik?.touched?.customer && formik?.errors?.customer
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#1a1f64", // default border color
                        borderWidth: "2px",
                      },
                    },
                  }}
                />
              </Stack>
            );
          }}
          renderOption={(props, option) => {
            const { id } = option;
            return (
              <li {...props} key={id}>
                <Typography variant="body2">{option?.business_name}</Typography>
              </li>
            );
          }}
        />
      </Stack>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          className="btn white-btn action-button "
          onClick={onClose}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white`}
          appearance="primary"
          type={"submit"}
          disabled={!formik.isValid}
          loading={loading}
        >
          Create
        </Button>
      </DialogActions>
    </form>
  );
};

export default SettleContractModal;
