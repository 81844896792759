import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { merchantApi } from "../../../redux/api/apiConfig";
import {
  CUSTOMER_TRANSACTION,
  EXPORT_FEE_REPORT,
  FEE_REPORT,
  MASTER_BALANCE,
  MASTER_TRANSACTION,
  SUPPLIER,
  SUPPLIER_BUS,
} from "../../../ApiUrl";
import { toast } from "react-toastify";
import { fCurrency } from "../../../utils/formatNumber";
import usePageTitle from "../../../hooks/usePageTitle";
import { FilterDropdown } from "../components";
import {
  ButtonResetFilter,
  ButtonSecondary,
  Iconify,
} from "../../../components";
import MonthlyFeeCollectionReportTable from "./MonthlyFeeCollectionReportTable";
import { StyledDatePicker } from "../../../components/datePicker";
import { reportTimePeriods } from "../common";
import { format } from "date-fns";
import {
  formatDate,
  getDateRange,
  searchableSelectFetchOptions,
} from "../../../utils/common";
import SearchableFilterDropdown from "../../../components/SearchableFilterDropdown";
import { DatePicker } from "@mui/x-date-pickers";

const MonthlyFeeCollectionReport = ({ client }) => {
  usePageTitle("Monthly Fee Collection Report", "View Report");
  const [transactionData, setTransactionData] = useState({});
  const [customerBalance, setCustomerBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [paginationFilter, setPaginationFilter] = useState({
    currentPage: 0,
    pageSize: 7,
  });
  const [filters, setFilters] = useState({
    date_range_after: getDateRange("month")?.fromDate,
    date_range_before: getDateRange("month")?.toDate,
    period: "month",
    business_id: null,
  });
  const theme = useTheme();
  async function getTransactionData(filters) {
    let params = {
      offset: paginationFilter?.currentPage * paginationFilter?.pageSize,
      limit: paginationFilter?.pageSize,
      transaction_category: "monthly",
      date_range_after: formatDate(filters?.date_range_after, "yyyy-MM-dd"),
      date_range_before: formatDate(filters?.date_range_before, "yyyy-MM-dd"),
      business_id: filters?.business_id?.value,
    };
    setLoading(true);
    merchantApi
      .get(`${FEE_REPORT}`, {
        params,
      })
      .then((response) => {
        if (response?.data) {
          setLoading(false);
          // console.log(response?.data);
          setTransactionData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.message || "Error getting transaction list"
        );
      });
  }

  useEffect(() => {
    getTransactionData(filters);
  }, [filters, paginationFilter]);

  const handleClearFilters = () => {
    setFilters({
      date_range_after: getDateRange("month")?.fromDate,
      date_range_before: getDateRange("month")?.toDate,
      period: "month",
      business_id: null,
    });
  };

  const handleExportExcel = async (filters) => {
    try {
      const response = await merchantApi.get(EXPORT_FEE_REPORT, {
        responseType: "blob",
        params: {
          date_range_after: formatDate(filters?.date_range_after, "yyyy-MM-dd"),
          date_range_before: formatDate(
            filters?.date_range_before,
            "yyyy-MM-dd"
          ),
          business_id: filters?.business_id?.value,
          transaction_category: "monthly",
        },
      });
      console.log("blob response", response);
      // Create a temporary URL for the blob
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobURL = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = blobURL;

      // Set the downloaded file name
      link.download = "Monthly-fee-collection-report.xlsx";

      // Append to the body, trigger click, and remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the object URL to free up memory
      URL.revokeObjectURL(blobURL);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box sx={{ padding: "0 24px 24px" }}>
      <Box sx={{ mt: 2 }}>
        <Stack
          sx={{
            overflowX: { xs: "auto" },
            overflowY: { xs: "visible", md: "unset" },
            padding: "5px 0",
          }}
          direction={"row"}
          justifyContent={"space-between"}
          gap={1}
          mt={2}
          width={"100%"}
        >
          <Stack
            sx={{ flexDirection: "row", gap: 1 }}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
          >
            <Stack sx={{ minWidth: "150px", flex: 1 }}>
              <FilterDropdown
                name="period"
                type="text"
                // label="Time Period"
                options={reportTimePeriods}
                onChange={(e) => {
                  const { fromDate, toDate } = getDateRange(e.target.value);
                  setFilters((prev) => ({
                    ...prev,
                    period: e.target.value,
                    date_range_after: fromDate,
                    date_range_before: toDate,
                  }));
                }}
                value={filters?.period}
                size={"small"}
                render={(period, i) => (
                  <MenuItem key={period.key} value={period.key}>
                    {period.name}
                  </MenuItem>
                )}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "56px", // Set desired height for the input
                    fontSize: "0.875rem", // Adjust font size if necessary
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "8px", // Adjust padding for content alignment
                  },
                  "& .MuiInputLabel-root": {
                    top: "50%", // Adjust label positioning if needed
                    transform: "translateY(-50%)", // Adjust label positioning if needed
                    left: "10px",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    top: "0px", // Adjust label positioning if needed
                  },
                }}
              />
            </Stack>
            <Stack sx={{ flexDirection: "row", gap: 1 }}>
              <Stack sx={{ width: "200px", flex: 1 }}>
                <DatePicker
                  value={filters?.date_range_after}
                  label="From Date"
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setFilters((prev) => ({
                      ...prev,
                      date_range_after: new Date(
                        format(newValue, "yyyy/MM/dd")
                      ),
                    }));
                  }}
                />
              </Stack>

              <Stack sx={{ width: "176px", flex: 1 }}>
                <DatePicker
                  value={filters?.date_range_before}
                  label="To Date"
                  disableFuture
                  format={"dd/MM/yyyy"}
                  onChange={(newValue) => {
                    setFilters((prev) => ({
                      ...prev,
                      date_range_before: new Date(
                        format(newValue, "yyyy/MM/dd")
                      ),
                    }));
                  }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ minWidth: "300px", flex: 1 }}>
              <SearchableFilterDropdown
                label="Select/Search Customer"
                fetchOptions={(query) =>
                  searchableSelectFetchOptions(SUPPLIER_BUS, query)
                }
                value={filters?.business_id}
                setSelectedCustomers={(val) =>
                  setFilters((prev) => {
                    return {
                      ...prev,
                      business_id: val,
                    };
                  })
                }
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: "row", gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
            <ButtonSecondary onClick={() => handleExportExcel(filters)}>
              <Iconify
                icon={"vscode-icons:file-type-excel"}
                sx={{ width: "20px" }}
              />{" "}
              Export
            </ButtonSecondary>
          </Stack>
        </Stack>
        <MonthlyFeeCollectionReportTable
          translist={transactionData}
          filters={paginationFilter}
          setFilters={setPaginationFilter}
          fetchTranslistLoading={loading}
          client={client}
        />
      </Box>
    </Box>
  );
};

export default MonthlyFeeCollectionReport;
