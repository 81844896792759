import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import axios from "axios";

const SearchableFilterDropdown = ({
  label,
  fetchOptions,
  setSelectedCustomers,
  ...others
}) => {
  const [options, setOptions] = useState([]); // Customer options
  const [inputValue, setInputValue] = useState(""); // Search input
  const [loading, setLoading] = useState(false); // Loading state for API call

  // Function to fetch data from API
  const loadOptions = async (query) => {
    setLoading(true);
    const response = await fetchOptions(query);
    setOptions(response);
    setLoading(false);
  };

  // Fetch customers when the input value changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (inputValue === "") {
        loadOptions("");
      } else {
        loadOptions(inputValue); // Clear options if input is empty
      }
    }, 300); // Add debounce delay to avoid too many API calls

    return () => clearTimeout(delayDebounceFn); // Cleanup timeout
  }, [inputValue]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label} // Display customer name
      filterOptions={(x) => x} // Disable local filtering to rely on API
      onChange={(event, newValue) => setSelectedCustomers(newValue)} // Update selected customers
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)} // Update search input
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || "Search"}
          variant="outlined"
          placeholder="Type to search"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...others}
    />
  );
};

export default SearchableFilterDropdown;
