import {
  Autocomplete,
  Box,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { TextFieldComponent, TextFieldSelect } from "../../../../components";
import { Button } from "rsuite";
import SearchNotFound from "../../../../components/SearchNotFound";
import { merchantApi } from "../../../../redux/api/apiConfig";
import { INCOMING_TRANSFER, SUPPLIER_BUS } from "../../../../ApiUrl";
import { toast } from "react-toastify";
import SearchableSelectField from "../../../../components/searchableSelectField";
import { searchableSelectFetchOptions } from "../../../../utils/common";

const UpdateInvoiceReceiptInfo = ({
  details,
  getTransferDetail,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      paid_by: details?.paid_by,
      cobo: details?.cobo
        ? {
            value: details?.cobo?.id,
            label: details?.cobo?.name,
          }
        : null,
    },
    onSubmit: addClickHandler,
  });

  async function addClickHandler(values) {
    const requestedData = {
      ...values,
      cobo: values?.cobo?.value,
    };

    try {
      setLoading(true);
      const response = await merchantApi.patch(
        `${INCOMING_TRANSFER}${details?.id}/`,
        requestedData
      );
      toast.success("Details Updated");
      setLoading(false);
      getTransferDetail();
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.data?.data?.message || "Something went wrong");
    }
  }

  return (
    <Box sx={{ mt: 3 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 2fr 1fr",
            gap: "20px",
            alignItems: "end",
          }}
        >
          <TextFieldComponent
            formik={formik}
            name={"paid_by"}
            type={"text"}
            label={"Paid By"}
          />
          <SearchableSelectField
            label="Customer"
            name="cobo"
            placeholder="Type to search"
            formik={formik}
            // fetchOptions={fetchOptions}
            fetchOptions={(query) =>
              searchableSelectFetchOptions(SUPPLIER_BUS, query)
            }
            required
          />
          <Button
            className={`btn action-button primary_color text-white`}
            appearance="primary"
            type={"submit"}
            // disabled={!formik.isValid}
            loading={loading}
          >
            Update
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UpdateInvoiceReceiptInfo;
