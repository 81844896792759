import React, { useState, useEffect } from "react";
import {
  TextField,
  List,
  ListItem,
  Checkbox,
  Button,
  ListItemText,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import axios from "axios";

const CustomerList = ({
  customersData,
  loading,
  handleRemove,
}) => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Fetch customers from API
  useEffect(() => {
    setCustomers(customersData || []);
  }, [customersData]);

  // Filter customers based on search term
  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle checkbox change
  const handleCheckboxChange = (customerId) => {
    setSelectedCustomers((prevSelected) => {
      if (prevSelected.includes(customerId)) {
        return prevSelected.filter((id) => id !== customerId);
      } else {
        return [...prevSelected, customerId];
      }
    });
  };

  // Handle remove button click
  //   const handleRemove = () => {
  //     if (selectedCustomers.length === 0) return;

  //     axios
  //       .delete("/api/customers", { data: { ids: selectedCustomers } })
  //       .then((response) => {
  //         // Filter out deleted customers from the UI
  //         setCustomers(
  //           customers.filter(
  //             (customer) => !selectedCustomers.includes(customer.id)
  //           )
  //         );
  //         setSelectedCustomers([]);
  //       })
  //       .catch((error) => {
  //         console.error("There was an error deleting the customers!", error);
  //       });
  //   };

  return (
    <div>
      <TextField
        label="Search Customers"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
        sx={{
          "& .MuiFormLabel-root": {
            backgroundColor: "transparent", // Ensures input field has no background
          },
        }}
      />

      <List sx={{ maxHeight: "500px", overflowY: "auto", mb: 2 }}>
        {loading ? (
          <ListItem>
            <CircularProgress size={20} />
          </ListItem>
        ) : filteredCustomers?.length > 0 ? (
          filteredCustomers.map((customer) => (
            <ListItem key={customer.id} dense>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCustomers.includes(customer.id)}
                    onChange={() => handleCheckboxChange(customer.id)}
                  />
                }
                label={customer.name}
              />
            </ListItem>
          ))
        ) : (
          <ListItem dense>No Customer Found!</ListItem>
        )}
      </List>

      <Button
        variant="contained"
        color="error"
        onClick={() => handleRemove(selectedCustomers)}
        disabled={selectedCustomers.length === 0}
      >
        Remove Selected
      </Button>
    </div>
  );
};

export default CustomerList;
