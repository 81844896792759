import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { startCase, toLower } from "lodash";
import {
  Box,
  Stack,
  Typography,
  LinearProgress,
  Avatar,
  Tooltip,
  Tabs,
  Tab,
  IconButton,
  Button,
} from "@mui/material";
import {
  formatWithoutTimezone,
  rowsPerPageOptions,
} from "../../../../utils/common";

import { DataGrid } from "@mui/x-data-grid";
import { formatCurrency, tableCellStyle } from "../../../../utils/common";

import createAvatar from "../../../../utils/createAvatar";
import { Label, ButtonGhost, Iconify } from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_INWARD_REMITTANCE } from "../../../../redux/actionTypes";
import InfoIcon from "@mui/icons-material/Info";
import { Routes } from "../../../../routes";
import { fCurrency } from "../../../../utils/formatNumber";
import UpdateTransactionStatusModal from "../../../../components/updateTransactionStatusModal/updateTransactionStatusModal";

export default function InvoiceReceiptsTransactionTable({
  translist,
  fetchTranslistLoading,
  counts,
  onTabFilterChange,
  currentTab,
  setCurrentTab,
  getTransferTransactionList,
  roles,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector((state) => state.filters.remittances.inward);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [selectedTransactionStatus, setSelectedTransactionStatus] =
    useState(null);
  // const loggedInUserRole = localStorage.getItem('user-info');

  const handleChangePage = (newPage) => {
    dispatch({
      type: UPDATE_INWARD_REMITTANCE,
      payload: { currentPage: newPage },
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: UPDATE_INWARD_REMITTANCE,
      payload: { currentPage: 0, pageSize: newSize },
    });
  };

  const createDataRow = (transaction) => {
    // const type = transaction.type;
    return {
      id: transaction?.id,
      date: formatWithoutTimezone(transaction?.created_date, "dd MMM yyyy"),
      customer:
        currentTab === "contract_payment"
          ? transaction?.lender?.name
          : transaction?.supplier?.name,
      maker: transaction?.maker?.name,
      checker: transaction?.checker?.name,
      amount: fCurrency(transaction?.amount),
      status: transaction?.status,
      action: {
        id: transaction?.id,
        possible_status: transaction?.possible_status,
        current_status: transaction?.status,
      },
    };
  };

  const rows =
    translist?.results?.length > 0
      ? translist.results.map((transaction) => createDataRow(transaction))
      : [];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: "header",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Tooltip sx={{ cursor: "default" }} title={params.formattedValue}>
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "customer",
      headerName: currentTab === "contract_payment" ? "Lender" : "Supplier",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "maker",
      headerName: "Maker",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "checker",
      headerName: "Checker",
      flex: 1,
      minWidth: 250,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Typography variant="body2" style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Label
            variant={"ghost"}
            color={
              params.formattedValue?.value === "Complete"
                ? "complete"
                : params.formattedValue?.value === "RejectedByChecker"
                ? "rejected"
                : "application submitted"
            }
            sx={{ textTransform: "capitalize" }}
          >
            {params.formattedValue?.display}
          </Label>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: "header",
      hide: !roles?.includes("checker"),
      renderCell: (params) => {
        return (
          <Button
            variant="contained" // Choose between "contained", "outlined", or "text"
            color="primary"
            type="button"
            style={{
              maxWidth: "300px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={() => {
              setSelectedTransactionStatus(params?.formattedValue);
              setUpdateStatus(true);
            }}
          >
            <Iconify icon="radix-icons:update" width={20} />
            Update Status
          </Button>
        );
      },
    },
  ];

  const TABS = [
    {
      value: "contract_payment",
      label: "Contract Payment",
      color: "rejected",
      count: counts.contract,
    },

    {
      value: "supplier_payment",
      label: "Customer Payment",
      color: "rejected",
      count: counts.supplier,
    },
  ];

  const onStatusFilterChanges = (newStatus) => {
    console.log(newStatus);
    onTabFilterChange(newStatus);
    setCurrentTab(newStatus);
  };

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 0px 8px 0px",
          borderRadius: "8px",
          // height: 825,
          width: "100%",
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={(e, value) => onStatusFilterChanges(value)}
          sx={{
            px: 2,
            bgcolor: "#f6f6f6",
            borderRadius: "8px 8px 0 0",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              sx={{ textTransform: "capitalize" }}
              disableRipple
              key={tab.label}
              value={tab.value}
              icon={
                <Label
                  sx={{ marginRight: "5px", cursor: "pointer" }}
                  variant={"ghost"}
                  color={tab.color}
                >
                  {tab.count}
                </Label>
              }
              label={tab.label}
              iconPosition={"start"}
            />
          ))}
        </Tabs>
        <DataGrid
          autoHeight
          sx={{
            borderRadius: "0 0 8px 8px",
            // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: "100%",
            cursor: "pointer",
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          rows={rows}
          columns={columns}
          rowHeight={80}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination={false} // Disable pagination
          hideFooter={true}
          loading={fetchTranslistLoading}
        />
      </Box>
      {updateStatus && (
        <UpdateTransactionStatusModal
          isOpen={updateStatus}
          onClose={() => {
            setUpdateStatus(false);
            setSelectedTransactionStatus(null);
          }}
          data={selectedTransactionStatus?.possible_status}
          id={selectedTransactionStatus?.id}
          isContract={currentTab === "contract_payment"}
          getTransferTransactionList={getTransferTransactionList}
        />
      )}
    </>
  );
}
